import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStore } from '../../store/auth.store';
import { tap } from "rxjs"

@Component({
  selector: 'appointment-banner',
  templateUrl: './appointment-banner.component.html',
  styleUrls: ['./appointment-banner.component.scss']
})
export class AppointmentBannerComponent {
 public hasAppointment: boolean = false;
 public islogged: boolean = false;

  constructor(protected router: Router, protected authStore: AuthStore) {
 
  }

  protected takeAppointment(evn: Event) {
    this.router.navigate(["/appointment/get-appointment"])
  }
}
