<section [ngClass]="'extra__info transition-3 ' + (utilsService.openMobileMenus ? 'info-opened' : '')">
  <div class="extra__info-inner">
    <div class="extra__info-close text-end">
      <a (click)="utilsService.handleOpenMobileMenu()" class="extra__info-close-btn cursor-pointer">
        <i class="fal fa-times"></i>
      </a>
    </div>

    <!-- side-mobile-menu start -->
    <nav class="side-mobile-menu d-block d-lg-none mm-menu">
      <ul>
        <ng-container *ngFor="let menu of mobile_menus_copy; let i = index">
          <li [ngClass]="'menu-item-has-children has-droupdown ' + (activeMenu === menu.title ? 'active' : '')">
            <a (click)="handleOpenMenu(menu.title)">{{ menu.title }}</a>
            <ul [ngClass]="'sub-menu ' + (activeMenu === menu.title ? 'active' : '')">
              <li *ngFor="let sub_m of menu.dropdownMenu; let index = index">
                <a (click)="navigate($event,sub_m.link)">{{ sub_m.title }}</a>
              </li>
            </ul>
          </li>
          <li *ngIf="!menu.dropdownMenu">
            <a (click)="navigate($event,menu.link??'')">{{ menu.title }}</a>
          </li>
        </ng-container>
      </ul>
    </nav>
    <!-- side-mobile-menu end -->
  </div>
</section>

<!--  body overlay  -->
<div
(click)="utilsService.handleOpenMobileMenu()"
  [ngClass]="'body-overlay transition-3 ' + (utilsService.openMobileMenus ? 'opened' : '')"
></div>
