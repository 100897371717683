<section class="related__product pb-60">
  <div class="container bg-white">
      <div class="row">
          <div class="col-xl-12">
              <div class="section__title-wrapper text-center mb-55">
                  <div class="section__title mb-10">
                      <h2 i18n>Prodotti correlati</h2>
                  </div>
                  <div class="section__sub-title">
                      <p i18n>Altri prodotti simili</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="row">
          <div *ngFor="let item of related_products" class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <app-product-item [product]="item"></app-product-item>
          </div>
      </div>
  </div>
</section>
