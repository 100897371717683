import { Component, EventEmitter, Input, Output } from '@angular/core';
import { tap } from 'rxjs';
import { AppointmentServiceModel } from 'src/app/shared/services/api.service';
import { AuthStore } from 'src/app/shared/store/auth.store';

@Component({
  selector: 'app-appointment-taker',
  templateUrl: './appointment-taker.component.html',
  styleUrls: ['./appointment-taker.component.scss']
})
export class AppointmentTakerComponent {
  @Input() public appointment!: AppointmentServiceModel;
  @Input() public saveInLocalstorage: boolean = false;
  @Output() onSelectedAppointment = new EventEmitter<AppointmentServiceModel>();

  constructor(protected store: AuthStore) {
  }

  SelectAppointment(evt: Event) {
    if (this.saveInLocalstorage) {
      localStorage.removeItem("selected-appointment");
      localStorage.setItem("selected-appointment", JSON.stringify(this.appointment));
    }

    this.store.getUserData().pipe(
      tap(result => {

        this.appointment.cardCode = result?.cardCode;
        this.appointment.clientName = result?.name;
        this.appointment.clientSurname = result?.surname;
        this.appointment.phoneNumber = result?.phone;
        this.appointment.endTime = new Date(this.appointment.startTime!.getTime() + 20 * 60000);     

        this.onSelectedAppointment.emit(this.appointment);
      })
    )
      .subscribe();
  }
}
