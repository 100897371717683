<section class="page__title p-relative d-flex align-items-center breadcrumb-bg" [style.background-image]="
showBg?'url('+bg_img+')':''">
    <div class="container">
        <div class="row" *ngIf="!isLoading">
            <div class="d-flex align-items-center justify-content-center">
                <div class=" text-center ">
                    <h3>{{title}}</h3>
                    <div class="page__title-breadcrumb">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><a routerLink="/" i18n>Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{subtitle}}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>