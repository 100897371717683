import { Component } from '@angular/core';
import { IPreviewQueryModel, IProductDTO } from 'src/app/shared/services/api.service';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-feature-products',
  templateUrl: './feature-products.component.html',
  styleUrls: ['./feature-products.component.scss']
})
export class FeatureProductsComponent {

  public feature_products:IProductDTO[] = [];

  constructor(public productService:ProductService){
  
    //TODO SVC CHECK THIS

    // var filter: IPreviewQueryModel = { page: 1, takeItems: 20 };
    // this.productService.products$(filter).subscribe((products) => {
    //   this.feature_products = products.filter(p => p.trending).slice(0,2)
    // });
  }

}
