import { Component, Input } from '@angular/core';
import { IPreviewQueryModel, IProductDTO } from 'src/app/shared/services/api.service';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-product-banner',
  templateUrl: './product-banner.component.html',
  styleUrls: ['./product-banner.component.scss']
})
export class ProductBannerComponent {
  @Input() style_2: boolean = false;
  @Input() style_3: boolean = true;

  public bannerProducts: IProductDTO[] = [];

  constructor(private productService: ProductService) {
    var filter: IPreviewQueryModel = {
      page: 1, takeItems: 20, getItemsBanner:true
    };
    this.productService.products$(filter).subscribe((products) => {
      this.bannerProducts = products.filter((p) => p.banner).slice(0, 2);
    });
  }
}
