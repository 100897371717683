import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs';
import { IProductDTO, RelatedProductFilter } from 'src/app/shared/services/api.service';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss'],
})
export class RelatedProductsComponent implements OnInit{
  @Input() productId: string | undefined;
  public related_products: IProductDTO[] = [];

  constructor(private productService: ProductService) { }


  ngOnInit() {
    if (this.productId) {
      var filter: RelatedProductFilter = {productId: this.productId} as RelatedProductFilter;
      this.productService
        .getRelatedProducts$(filter).pipe(
          tap(products=> {
            this.related_products=[];
            products.forEach(e=> this.related_products.push(e));
        }))
        .subscribe();
    }
  }
}
