<!-- header start -->
<app-header-one [transparent]="false" [white_bg]="false"></app-header-one>
<!-- header end -->

<main class="container-fluid">

    <!-- breadcrumb start -->
    <app-breadcrumb-one title="Ritiro merce" subtitle="Ritiro merce" [showBg]="false"></app-breadcrumb-one>
    <!-- breadcrumb end -->
    <div class="pt-25 mx-auto">

        <div *ngIf="anyAppointments; else anyAppointments">

            <div class="container">
                <div class="alert alert-info">
                    <h3 class="alert-heading">Gentile Cliente,</h3>
                    <div class>
                        inserisci il tuo codice fiscale per controllare il tuo attuale appuntamento o per prenotarne uno
                        per
                        questo mese.
                        <hr>
                        <form [formGroup]="checkFiscalCodeForm" (ngSubmit)="onSubmitFiscalCode()">
                            <div class="d-flex row-gap-2 gap-2 align-items-center">
                                <div class="w-100 form-floating  mb-1">
                                    <input type="email" class="form-control" id="codiceFiscale" [(ngModel)]="cF"
                                        placeholder="Inserire codiceFiscale" formControlName="codiceFiscale">
                                    <label for="codiceFiscale">Codice Fiscale</label>
                                    <div
                                        *ngIf="checkFiscalCodeForm.get('codiceFiscale')?.invalid && checkFiscalCodeForm.get('codiceFiscale')?.touched">
                                        <p *ngIf="checkFiscalCodeForm.get('codiceFiscale')?.errors?.['required']">Codice
                                            Codice Fiscale obbligatorio</p>
                                    </div>
                                </div>
                                <div class="flex-shrink">
                                    <button type="submit" class="btn btn-outline-dark">Controlla</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="(userFiscalcodeChecked)">
                <div class="pt-25 mx-auto" *ngIf="!(!!appointmentWasTaken)">
                    <div class="pt-25 mx-auto">
                        <div class="d-flex flex-md-row flex-column align-items-center justify-content-start justify-content-lg-center w-gap-2- gap-2 shadow p-3 mb-5 bg-body-tertiary rounded "
                            *ngIf="!isLoading; else loader">
                            <div class="d-flex  justify-content-center">
                                <ngb-datepicker [(ngModel)]="today!" [minDate]="start!" [maxDate]="end!"
                                    class="shadow-md rounded w-100" [markDisabled]="isDateToMarkAsDisabled"
                                    outsideDays="collapsed" navigation="arrows" [displayMonths]="1"
                                    (dateSelect)="onDateSelected($event)"></ngb-datepicker>
                            </div>
                            <div class="d-flex flex-column w-100 py-2 justify-content-start" id="apps-container">
                                <div class="d-flex flex-md-row flex-column overflow-y-auto gap-2 row-gap-2  justify-content-start"
                                    id="scheduler-slot-container"
                                    *ngIf="hasAppointmentDicLenghtMajorThan0(); else noAppointments">

                                    <div class="" *ngFor="let item of appointmentsDicFiltered | keyvalue">
                                        <div class="d-flex flex-column row-gap-2" id="{{item.key}}">
                                            <app-appointment-taker *ngFor="let item of item.value" [appointment]="item"
                                                (onSelectedAppointment)="onTakerSelectedAppointment($event)" />
                                        </div>
                                    </div>
                                </div>
                                <ng-template #noAppointments>
                                    <div class="alert alert-info w-100 my-auto">
                                        <h4 class="alert-heading">
                                            Non ci sono più appuntamenti disponibili per la data selezionata
                                        </h4>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row justify-content-center gap-2 rounded py-2 my-2"
                        *ngIf="!!selectedAppointment" id="scheduler-confirmation">
                        <div class="card  bg-theme w-100 mx-2">
                            <div class="card-body">
                                <h5 class="card-title">Ci siamo quasi:</h5>
                                <p>
                                    Controlla i dati e conferma l'appuntamento:
                                </p>
                                <hr />

                                <form [formGroup]="checkoutForm" (ngSubmit)="takeAppointment($event)">
                                    <div class="" [ngClass]="!isServerSaving?'':'placeholder-glow'">
                                        <div class="d-flex flex-column row-gap-2 mb-10">
                                            <div class="">
                                                <label>Name **</label>
                                                <input type="text" class="form-control" formControlName="firstName"
                                                    required>
                                                <div *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched || firstName?.hasError)"
                                                    class="alert alert-danger my-1">
                                                    <div *ngIf="firstName?.errors?.['required']" i18n>Nome destinario
                                                        obbligatorio
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="">
                                                <label>Cognome **</label>
                                                <input type="text" class="form-control" formControlName="lastName"
                                                    required>
                                                <div *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched|| lastName?.hasError)"
                                                    class="alert alert-danger my-1">
                                                    <div *ngIf="lastName?.errors?.['required']" i18n>Nome destinario
                                                        obbligatorio
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="">
                                                <label>Recapito Telefonico: **</label>
                                                <input type="text" class="form-control" formControlName="phone"
                                                    required>
                                                <div *ngIf="phone?.invalid && (phone?.dirty || phone?.touched|| phone?.hasError)"
                                                    class="alert alert-danger my-1">
                                                    <div *ngIf="phone?.errors?.['required']" i18n>Recapito telefonico
                                                        necessario
                                                        in
                                                        caso
                                                        di comunicazioni urgenti!</div>
                                                    <div *ngIf="phone?.errors" i18n>Numero di telefono non valido,
                                                        controlla che
                                                        non
                                                        ci
                                                        siano spazi o che il numero inserito abbia 10 numeri</div>
                                                </div>
                                            </div>
                                            <div class="">
                                                <label>Codice tessera:</label>
                                                <input type="text" class="form-control" formControlName="cardNumber">
                                                <div *ngIf="(checkoutForm?.errors?.['atLeastOneRequired'])"
                                                    class="alert alert-danger my-1">
                                                    <div i18n>
                                                        Devi inserire ilcodice tessera o il codice fiscale per prendere
                                                        un
                                                        appuntamento. </div>
                                                </div>
                                            </div>
                                            <div class="">
                                                <label>Codice Fiscale:</label>
                                                <input type="text" class="form-control" formControlName="cf">
                                                <div *ngIf="(checkoutForm?.errors?.['atLeastOneRequired'])"
                                                    class="alert alert-danger my-1">
                                                    <div i18n>Devi inserire ilcodice tessera o il codice fiscale per
                                                        prendere un
                                                        appuntamento. </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer d-flex flex-row justify-content-center"
                                            [ngClass]="!isServerSaving?'':'placeholder'">
                                            <button class="btn btn-light bg-text-light text-wrap border-dark text-wrap"
                                                *ngIf="!isServerSaving;else noBtnActonOnload" type="submit">Clicca per
                                                confermare
                                                l'appuntamento del <br />
                                                <span class="fw-bolder">{{selectedAppointment.startTime
                                                    |date:'short'}}</span>
                                            </button>
                                            <ng-template #noBtnActonOnload>
                                                <button
                                                    class="btn btn-light bg-text-light text-wrap border-dark text-wrap disabled"
                                                    type="submit">
                                                    Clicca per confermare
                                                    l'appuntamento del <br />
                                                    <span class="fw-bolder">{{selectedAppointment.startTime
                                                        |date:'short'}}</span>
                                                </button>
                                            </ng-template>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                    <ng-template #loader>
                        <app-loader [IsLoading]="isLoading"></app-loader>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <ng-template #anyAppointments>
        <div class="container">

            <div class="alert w-100 alert-warning">
                <h3 class="alert-heading">Ci dispiace,</h3>
                <p class="fs-6">purtroppo per questo mese non ci sono più appuntamenti disponibili: torna <span class="fw-bolder">{{nextMonth! | date:"fullDate"}}</span> .</p>
                <hr>
                <div class="d-flex flex-row justify-content-center">
                    <a class="btn btn-outline-dark" routerLink="/">Home</a>
                </div>
            </div>
        </div>

    </ng-template>

    <ng-template #appointmentAlreadyTaken>
        <div class="container">

            <div class="alert w-100 alert-danger">
                <h3 class="alert-heading">Attenzione,</h3>
                <p class="fs-6">hai già preso un appuntamento per questo mese:</p>

                <div class="d-flex flex-md-row flex-column my-2 gap-2 row-gap-2 justify-content-md-between">
                    <div class="d-flex flex-column">
                        <div class="fs-4 d-flex flex-column flex-md-row row-gap-2 gap-2 my-auto">
                            <span>{{appointmentWasTaken?.startTime|date:"dd MMMM yyyy"}}</span>
                            <span>{{appointmentWasTaken?.startTime|date:"'alle' HH:mm "}}</span>
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <button class="btn btn-danger" (click)="deleteAppointment($event)">Elimina appuntamento</button>
                    </div>
                </div>
                <hr>
                <div class="d-flex flex-row justify-content-center">
                    <a class="btn btn-outline-dark" routerLink="/">Home</a>
                </div>
            </div>
        </div>
    </ng-template>
</main>