<div class="sidebar__widget mb-55" *ngIf="false">
  <div class="sidebar__widget-title mb-30">
    <h3>Prezzo</h3>
  </div>
  <div class="sidebar__widget-content">
    <div class="price__slider">
      <div id="slider-range">
        <ngx-slider [(value)]="min" [(highValue)]="max" [options]="options" (userChangeEnd)="appliedFilter($event)"
          *ngIf="isBrowser">
        </ngx-slider>
      </div>
      <div>
        <div class="d-flex flex-row justify-content-center">
          <button (click)="handlePriceRoute()" i18n class="text-white">
           Applica <span class="input-range">
              {{ min | currency:"EUR"}} - {{ max | currency:"EUR" }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>