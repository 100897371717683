import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swiper from 'swiper';
import { EffectFade, Pagination } from 'swiper/modules';
import { BucketMediaFileInfo, Service } from 'src/app/shared/services/api.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-hero-slider-two',
  templateUrl: './hero-slider-two.component.html',
  styleUrls: ['./hero-slider-two.component.scss']
})
export class HeroSliderTwoComponent implements OnInit {

  @ViewChild('heroSliderContainer') heroSliderContainer!: ElementRef;
  public swiperInstance: Swiper | undefined;
  
public aws_home_slider:BucketMediaFileInfo[]=[];
/**
 *
 */
constructor(protected service:Service) {
  
}

 ngOnInit(): void {
   this.service.getAllBacketMediaFiles2("Homepage_Banner_").pipe(
    map(i=>{
      if(!!i && i.success){
        this.aws_home_slider=[];
        i.data?.forEach(img=>{
          this.aws_home_slider.push(img);
        });
      }
    })
   ).subscribe();
  }
  
  ngAfterViewInit() {
    if (this.heroSliderContainer) {
      this.swiperInstance = new Swiper('.slider-active', {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        effect : 'fade',
        modules:[Pagination,EffectFade],
        pagination: {
          clickable: true,
          el:'.tp-slider-dot-2'
        },
      })
    }
  }
}
