import { IMenuType, IMobileMenu } from "../types/menu-d-t";

const menuData: IMenuType[] = [
  {
    link: '/',
    title: 'Home',
    hasDropdown: false,
    megamenu: false,
    dropdownItems: []
  },
  {
    link: '/shop',
    title: 'I Nostri Prodotti',
    hasDropdown: true,
    megamenu: true,
    dropdownItems: [
      {
        link: '/shop',
        title: 'Categorie',
        dropdownMenu: [
          // { link: '/shop', title: 'Standard Shop Page' },
          // { link: '/shop/shop-right', title: 'Shop Right Sidebar' },
          // { link: '/shop/shop-4-col', title: 'Shop 4 Column' },
          // { link: '/shop/shop-3-col', title: 'Shop 3 Column' },
          // { link: '/shop', title: 'Shop Page' },
          // { link: '/shop', title: 'Shop Page' },
          // { link: '/shop', title: 'Shop Infinity' },
          // { link: '/shop', title: 'Shop Infinity' },
          // { link: '/shop', title: 'Shop Infinity' },
          // { link: '/shop', title: 'Shop Infinity' },
        ]
      },

    ]
  },
  {
    link: '/appointment/get-appointment',
    title: 'Appuntamento Merce',
    hasDropdown: false,
    megamenu: false,
    dropdownItems: []
  }, {
    link: '/pages/static/8ff2084e-e477-4426-99e0-b85455e9e4d1',
    title: 'Vendi con Noi',
    hasDropdown: false,
    megamenu: false,
    dropdownItems: []
  },
  {
    link: '',
    title: 'Altro',
    hasDropdown: true,
    megamenu: false,
    dropdownItems: [
    //added in nav-manus components, retrived from server
    ]
  },
]

export default menuData;

// mobile menus
export const mobile_menus: IMobileMenu[] = [
  {
    title: "Sito",
    dropdownMenu: [
      { link: "/", title: "Home" },
      { link: "/shop/cart", title: "Carrello" },
    ]
  },
  {
    title: "SHOP",
    dropdownMenu: [
     //generated from server call 
    ],
  },
  {
    title: "Appuntamenti",
    dropdownMenu: [{ link: "/appointment/get-appointment", title: "Appuntamento Merce" }]
  },
  {
    title: "Vendi con Noi",
    dropdownMenu: [{ link: "/pages/static/8ff2084e-e477-4426-99e0-b85455e9e4d1", title: "Vendi con Noi" }]
  },
  {
    title: "Altro",
    dropdownMenu: [
//generated from server
    ]
  }, 
];
