<!-- header start -->
<app-header-one [transparent]="false" [white_bg]="true"></app-header-one>
<!-- header end -->

<main>
    <!-- breadcrumb start -->
    <app-breadcrumb-one title="Riepilogo ordine" subtitle="Riepilogo ordine"></app-breadcrumb-one>
    <!-- breadcrumb end -->
    <section class="checkout-area pb-70">


        <div class="container mt-5 bg-white border rounded py-2">

            <div
                class="d-flex flex-md-row flex-column row-gap-3 p-1 align-items-center bg-body-secondary rounded bg-opacity-50 py-2">
                <div class="w-100">
                    <div class="row">
                        <div class="d-flex flex-row gap-2 mb-2"> <span class="fw-bolder">Codice:</span>
                            <span>{{order?.orderCode}}</span>
                        </div>
                        <div class="d-flex flex-row gap-2 mb-2"> <span class="fw-bolder">Pagamento:</span>
                            <span> {{orderStatusName}}</span>
                        </div>
                        <div class="d-flex flex-column gap-2" *ngIf="!!order?.trackingNumber">
                            <span class="fw-bolder">Spedizione:</span>
                            <span>{{order?.trackingNumber}}</span>
                        </div>
                    </div>
                </div>
                <div class="flex-shrink">
                    <button class="btn btn-primary text-nowrap" (click)="createPdfSummary($event)"
                        *ngIf="!isLoadingPdf"> Salva in PDF</button>
                </div>
            </div>

            <div
                class="d-flex flex-md-row flex-column justify-content-md-between justify-content-start align-items-md-center align-items-start  row-gap-2 px-2 py-2">
                <div class="d-flex flex-column gap-1 pb-10">
                    <h4 class="fw-bold">Indirizzo di Spedizione</h4>
                    <span>{{order?.destinationAddress?.firstName}} {{order?.destinationAddress?.lastName}}</span>
                    <span>{{order?.destinationAddress?.fullAddress}}</span>
                    <span>{{order?.destinationAddress?.email}}</span>
                    <span>{{order?.destinationAddress?.phoneNumber}}</span>
                </div>

                <div class="d-flex flex-column gap-1" *ngIf="false">
                    <h4 class="fw-bold">Indirizzo di Fatturazione</h4>
                    <ng-container *ngIf="order?.useOtherInvoiceAddress; else sameInvoiceAddress">
                        <span>{{order?.invoiceAddress?.firstName}} {{order?.destinationAddress?.lastName}}</span>
                        <span>{{order?.invoiceAddress?.fullAddress}}</span>
                        <span>{{order?.invoiceAddress?.email}}</span>
                        <span>{{order?.invoiceAddress?.phoneNumber}}</span>
                    </ng-container>
                    <ng-template #sameInvoiceAddress>
                        <span>{{order?.destinationAddress?.firstName}} {{order?.destinationAddress?.lastName}}</span>
                        <span>{{order?.destinationAddress?.fullAddress}}</span>
                        <span>{{order?.destinationAddress?.email}}</span>
                        <span>{{order?.destinationAddress?.phoneNumber}}</span>
                    </ng-template>
                </div>
            </div>

            <div class="pt-20 d-flex flex-column flex-md-row px-2 gap-2 row-gap-2">
                <div class="d-flex flex-column w-100">
                    <h4 class="fw-bold">Tipologia di Pagamento</h4>
                    <div class="d-flex flex-row pt-25">
                        <app-payments [payment]="order?.paymentMethod"></app-payments>
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <h4 class="fw-bold">Tipologia di Spedizione</h4>
                    <app-delivery-company [deliveryCompany]="order?.deliveryData"
                        [showNotes]="true"></app-delivery-company>
                </div>
            </div>

            <h4 class="mt-4">Lista Articoli</h4>
            <div class="table-content table-responsive">
                <table class="table table-striped table-hover table-sm  align-middle">
                    <thead>
                        <tr>
                            <th class="cart-product-name" i18n>Prodotto</th>
                            <th class="product-price" i18n>Prezzo singolo</th>
                            <th class="product-quantity" i18n>Quantità</th>
                            <th class="product-subtotal" i18n>Totale</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let item of order?.previewProductList">

                            <td class="product-name">
                                {{item.title}}
                            </td>
                            <td class="product-price"><span
                                    class="amount">{{item.price!.toFixed(2)|currency:"EUR":"symbol"}}</span></td>
                            <td class="product-quantity">
                                <div class="cart-plus-minus">
                                    {{item.quantity}}
                                </div>
                            </td>
                            <td class="product-subtotal">
                                <span class="amount text-nowrap">
                                    {{item.sellPrice! }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row">
                <div class="col ml-auto">
                    <div class="cart-page-total">
                        <h2 i18n>Riepilogo</h2>
                        <ul class="mb-20">
                            <li i18n>
                                Spedizione
                                <span>{{(order?.deliveryData?.price??0).toFixed(2)|currency:"EUR":"symbol"}}</span>
                            </li>
                            <li i18n>
                                Subtotal
                                <span>{{(totalOrderItems).toFixed(2)|currency:"EUR":"symbol"}}</span>
                            </li>
                            <li i18n>Total
                                <span>{{(totalOrder).toFixed(2)|currency:"EUR":"symbol"}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>