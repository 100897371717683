<!-- search area start -->
<section class="header__search white-bg transition-3 overflow-y-auto" [class.search-opened]="utilsService.isSearchOpen"
  style="height:42vh">
  <div class="container" *ngIf="!isLoading">
    <div class="row">
      <div class="col-xl-12">
        <div class="header__search-inner text-center">
          <form (ngSubmit)="handleSearchSubmit()">
            <div class="d-flex justify-content-center align-items-center pb-35 px-2">
              <div class="w-100">
                <h3 class="text-start" i18n>Ricerca veloce</h3>
              </div>
              <div class="flex-shrink">
                <a (click)="utilsService.handleSearchOpen()" class="header__search-btn-close cursor-pointer">
                  <i class="fal fa-times"></i>
                </a>
              </div>
            </div>

            <div class="h5 f-left w-100 pb-10">Titolo o Descrizione</div>

            <div class="header__search-input p-relative">
              <input type="search" class="form-control border" i18n-placeholder="Ricerca testuale..."
                placeholder="Ricerca testuale..." [(ngModel)]="searchText" name="searchText" (keyup)="onKey($event)" />
            </div>
            <hr />

            <div class="header__search-categories" *ngIf="false">
              <div class="h5 f-left w-100 pb-10">Seleziona le categorie</div>
              <ul class="search-category">
                <li *ngFor="let c of categories; let i = index" (click)="handleProductType(c.id!)">
                  <a class="cursor-pointer" [class.active]="isCategorySelected(c.id!)">
                    {{ c.name }}
                  </a>
                </li>
              </ul>
            </div>

            <div class="d-flex flex-row justify-content-center pt-20 pb-20">
              <button type="submit" class="os-btn">Cerca</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- body overlay -->
<div (click)="utilsService.handleSearchOpen()" class="body-overlay transition-3" data-bs-backdrop="static"
  [class.opened]="utilsService.isSearchOpen"></div>
<!-- search area end -->