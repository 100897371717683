import { JsonPipe, ViewportScroller, } from '@angular/common';
import { Component, OnInit, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { flip } from '@popperjs/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, concat, concatMap, map, of, switchMap, tap } from 'rxjs';
import { AppointmentServiceModel, Service } from 'src/app/shared/services/api.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { AuthStore } from 'src/app/shared/store/auth.store';
import IAppointmentFilter from 'src/app/shared/types/appointment-filter';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AuthProfile } from 'src/app/shared/data/auth-profile';
import { Router } from '@angular/router';
@Component({
  selector: 'app-appointment-stepper',
  templateUrl: './appointment-stepper.component.html',
  styleUrls: ['./appointment-stepper.component.scss']
})

export class AppointmentStepperComponent implements OnInit {

  public today: NgbDateStruct | undefined;
  public start: NgbDateStruct | undefined;
  public end: NgbDateStruct | undefined;
  protected isLoading: boolean = false;
  public appointmentsOfMonth: AppointmentServiceModel[] = [];
  public appointmentsDicFiltered!: { [key: string]: AppointmentServiceModel[] };
  public selectedAppointment: AppointmentServiceModel | null = null;
  public checkoutForm!: FormGroup;
  public checkFiscalCodeForm!: FormGroup;
  protected anyAppointments: boolean = false;
  protected isLogged: boolean = false;
  protected appointmentWasTaken: AppointmentServiceModel | null = null;
  protected userData: AuthProfile | null | undefined;
  protected userFiscalcode: string | undefined;
  protected userFiscalcodeChecked: boolean = false;
  protected isServerSaving: boolean = false;
  protected cF: string = '';
  protected nextMonth:Date| undefined;

  constructor(protected apiService: Service, protected authStore: AuthStore, public utilsService: UtilsService, protected toast: ToastrService, protected viewScroller: ViewportScroller, protected router: Router) {
    this.isLoading = true;
    let dateTime = new Date();
    this.appointmentsDicFiltered = {};
    this.today = { year: dateTime.getFullYear(), month: dateTime.getMonth() + 1, day: dateTime.getDate().valueOf() };
    this.start = { year: dateTime.getFullYear(), month: dateTime.getMonth() + 1, day: 1 };
    var lastDayOfMonth = new Date(dateTime.getFullYear(), dateTime.getMonth() + 1, 0);
    this.end = { year: lastDayOfMonth.getFullYear(), month: lastDayOfMonth.getMonth() + 1, day: lastDayOfMonth.getDate() };
    this.checkFiscalCodeForm = new FormGroup({
      codiceFiscale: new FormControl(this.cF, Validators.required)
    });


    authStore.IsLogged$.pipe(tap(e => { this.isLogged = e; })).subscribe();
    const month = dateTime.getMonth();
    this.nextMonth = new Date(dateTime.getFullYear(), month + 1, 1);

    authStore.getUserData().pipe(
      tap(user => {
        this.userData = user;
        this.cF = this.userData?.fiscalcode ?? '';       
      })
    ).subscribe();
  }

  ngOnInit(): void {
    let dateTime = new Date();
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth();

    let startDate = new Date(year, month, dateTime.getDate().valueOf());
    var lastDayOfMonth = new Date(dateTime.getFullYear(), month + 1, 1);
    var endDate = new Date(year, month + 1, 1);

    var filter: IAppointmentFilter = {
      OnlyFreeAppointments: true,
      StartDate: startDate,
      EndDate: endDate
    }

    this.apiService.hasAnyMontlyAppointments().pipe(
      concatMap(r => {
        if (!!r && r.success) {
          this.anyAppointments = (r.data ?? false);
        } 
        return of(r);
      })
    ).subscribe(r => {
      if (!!r && r.success) {
        this.anyAppointments = (r.data ?? false);
        if (this.anyAppointments) {
          this.retriveAppointments(filter);
        }else {
          this.nextMonth = new Date(dateTime.getFullYear(), month + 1, 1);

          this.isLoading = false;
        }
      }
    })

  }

  onSubmitFiscalCode() {
    if (this.checkFiscalCodeForm.valid) {

      this.userFiscalcode = this.checkFiscalCodeForm.get('codiceFiscale')?.value;

      this.apiService.getappointmentbyfiscacode(this.checkFiscalCodeForm.get('codiceFiscale')!.value).pipe(
        catchError(e => {
          console.error(JSON.stringify(e));
          return of();
        }),
        tap(result => {
          if (!!result && result.success) {

            if (!!result.data) {
             
            //  result.data?.startTime!.setMinutes(result.data?.startTime!.getMinutes() + result.data?.startTime!.getTimezoneOffset());
              this.appointmentWasTaken = result.data!;

              this.utilsService.handleOpenAppointmentConfirmationModal(result.data!);
            }
          }
          this.userFiscalcodeChecked = true;
        })
      ).subscribe();
    }
  }

  retriveAppointments(filter: IAppointmentFilter) {
    
    this.apiService.getAllAppointments(JSON.stringify(filter)).pipe(
      catchError(e => { console.log(e); return of(); }),
      map(result => {

        if (result.success) {
          var now = new Date();

          this.appointmentsOfMonth = [];
          this.appointmentsDicFiltered = {};
          //result.data?.forEach(e=> e.startTime!.setMinutes(e?.startTime!.getMinutes() + e?.startTime!.getTimezoneOffset()))
          this.appointmentsOfMonth = result.data!.filter(e => { var day = e.startTime?.getDate(); return e.startTime!.getDate() > now.getDate() });

         // this.anyAppointments = result.data!.length == 0 || result.data?.filter(e => { var day = e.startTime?.getDate(); return day! >= this.today?.day! }).length == 0;
          var todayAppointments = result.data!.filter(e => { var day = e.startTime?.getDate(); return day == this.today?.day && e.startTime!.getTime() >= now.getTime() });
          this.appointmentsDicFiltered = this.utilsService.arrayToDictionary(todayAppointments, 7);

        } else {
          console.log(result.message);
          this.appointmentsOfMonth = [];
          this.appointmentsDicFiltered = {};
        }
        this.isLoading = false;
      })
    ).subscribe();
  }

  isDateToMarkAsDisabled(date: NgbDate): boolean {

    let dateTime = new Date();
    //date parte con i gennaio = 0; ngbDate con gennaio a 1; tostring locale ragiona con gennaio=0! 
    var dayOfWeekName = new Date(date.year, date.month - 1, date.day).toLocaleDateString('it-IT', { weekday: 'long' });
    const noDays: string[] = ['domenica', 'lunedì'];

    //data minore di oggi.
    var result = (date.day < dateTime.getDate());
    return result || noDays.some(e => e === dayOfWeekName);
  }

  onDateSelected(date: NgbDate) {

    var todayAppointments = this.appointmentsOfMonth.filter(e => e.startTime?.getDate() == date.day);
    this.appointmentsDicFiltered = {};
    this.appointmentsDicFiltered = this.utilsService.arrayToDictionary(todayAppointments, 7);
  }

  hasAppointmentDicLenghtMajorThan0(): boolean {
    return Object.keys(this.appointmentsDicFiltered).length > 0;
  }

  onTakerSelectedAppointment(appointment: AppointmentServiceModel): void {

    this.selectedAppointment = appointment;
    var fiscal = "";

    if (!!this.userFiscalcode) {
      this.selectedAppointment.fiscalcode = this.userFiscalcode;
      fiscal = this.userFiscalcode;
    }


    if (!!this.userData) {
      this.selectedAppointment.clientName = this.userData.name;
      this.selectedAppointment.clientSurname = this.userData.surname;
      this.selectedAppointment.phoneNumber = this.userData.phone;
      this.selectedAppointment.cardCode = this.userData.cardCode;
      this.selectedAppointment.fiscalcode = this.userData.fiscalcode;
      fiscal = this.userData.fiscalcode;
    }

    this.checkoutForm = new FormGroup({
      firstName: new FormControl(this.selectedAppointment.clientName, Validators.required),
      lastName: new FormControl(this.selectedAppointment.clientSurname, Validators.required),
      phone: new FormControl(this.selectedAppointment.phoneNumber, [Validators.required, Validators.pattern(/^(\+39\s?)?3\d{2}[\s.-]?\d{6,7}$|^(\+33\s?)?[1-9]\d{1}[\s.-]?\d{2}[\s.-]?\d{2}[\s.-]?\d{2}$|^(\+49\s?)?\(?\d{3,4}\)?[\s.-]?\d{6,7}$|^(\+34\s?)?\d{3}[\s.-]?\d{3}[\s.-]?\d{3}$|^(\+44\s?)?\d{4}[\s.-]?\d{6}$|^(\+44\s?)?\d{3}[\s.-]?\d{3}[\s.-]?\d{4}$/)]),
      cf: new FormControl(fiscal),
      cardNumber: new FormControl(this.selectedAppointment.cardCode),
    }, { validators: this.atLeastOneRequiredValidator('cf', 'cardNumber') });

  }

  takeAppointment(ev: Event) {
    this.isServerSaving = true;
    if (!this.checkoutForm.valid) {
      this.isServerSaving = false;

      return;
    }

    this.selectedAppointment!.clientName = this.firstName!.value;
    this.selectedAppointment!.clientSurname = this.lastName!.value;
    this.selectedAppointment!.phoneNumber = this.phone!.value;
    this.selectedAppointment!.cardCode = this.cardNumber!.value;
    this.selectedAppointment!.fiscalcode = this.cf!.value;

    this.apiService.addOrUpdateAppointment(this.selectedAppointment!).pipe(
      catchError(e => {
        console.error(JSON.stringify(e));
        return of();
      }),
      tap(result => {
        if (result.success) {
          this.utilsService.handleOpenAppointmentConfirmationModal(this.selectedAppointment!);
          if (this.isLogged) {
            //aggiornare il claim

            var day = this.selectedAppointment?.startTime!.getDate();
            var month = this.selectedAppointment?.startTime!.getMonth()! + 1; // I mesi sono indicizzati da 0
            var year = this.selectedAppointment?.startTime!.getFullYear();
            var hours = this.selectedAppointment?.startTime!.getHours();
            var minutes = this.selectedAppointment?.startTime!.getMinutes()!;
            var d = `${day}/${month}/${year} ${hours}:${minutes < 10 ? '0' + minutes : minutes}`;

            this.authStore.AddAppointment(d, result.data?.newAppointmentId!);
          }

        } else {
          this.toast.error("Appuntamento NON preso: " + result.message, `Attenzione appuntamento FALLITO.`, { disableTimeOut: true, });
        }
      })
    ).subscribe(r => {
      this.isServerSaving = false;
      this.router.navigate(['/'])
    });
  }

  filterEventsBySpecificDate(specificDate: Date): AppointmentServiceModel[] {
    return this.appointmentsOfMonth.filter(app => {
      const eventDate = app.startTime;
      return eventDate?.getFullYear() === specificDate.getFullYear() &&
        eventDate?.getMonth() === specificDate.getMonth() &&
        eventDate?.getDate() === specificDate.getDate();
    });
  }

  atLeastOneRequiredValidator(field1: string, field2: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const control1 = formGroup.get(field1);
      const control2 = formGroup.get(field2);

      if (control1 && control2 && !control1.value && !control2.value) {
        return { atLeastOneRequired: true };
      }
      return null;
    };
  }

  deleteAppointment(evt: Event) {
    this.apiService.deleteAnAppointment(this.appointmentWasTaken?.appointmentId!).pipe(
      tap(result => {
        if (result.success) {
          //notificare la cancellazione
          this.authStore.RemoveAppointment();
          this.toast.success("Appuntamento eliminato con successo.");
          this.router.navigate(["/"]);
        } else {
          //notificare l'errore
          this.toast.error(result.message, "Ops...", { tapToDismiss: true })
        }
      })
    ).subscribe();
  }

  get firstName() {
    return this.checkoutForm.get('firstName');
  }
  get lastName() {
    return this.checkoutForm.get('lastName');
  }

  get phone() {
    return this.checkoutForm.get('phone');
  }
  get cf() {
    return this.checkoutForm.get('cf');
  }
  get cardNumber() {
    return this.checkoutForm.get('cardNumber');
  }
}

