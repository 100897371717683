import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuthStore } from 'src/app/shared/store/auth.store';

@Component({
  selector: 'app-extra-info',
  templateUrl: './extra-info.component.html',
  styleUrls: ['./extra-info.component.scss']
})
export class ExtraInfoComponent  {
  isUserLogged: boolean = false;
  constructor(protected router: Router, public authStore: AuthStore) {

  }
 
   logout(evt: Event) {
    this.authStore.logout();
    this.isUserLogged = false;
  }

}
