<div class="mini-cart">
  <div *ngIf="cartService.getCartProducts().length === 0">
    <h5 i18n>Non ci sono articoli nel carrello</h5>
  </div>
  <div *ngIf="cartService.getCartProducts().length > 0" class="mini-cart-inner">
    <ul class="px-2"
      [ngClass]="['mini-cart-list', cartService.getCartProducts().length === 1 ? 'slider-height_1' :  cartService.getCartProducts().length === 2 ? 'slider-height_2' : 'slider-height']">
      <li *ngFor="let item of cartService.getCartProducts()" class="border rounded p-1">
        <div class="cart-content f-left text-left">
          <div class="d-flex">
            <div class="w-100">
              <h5>
                <a [routerLink]="['/shop/shop-details', item.id]">{{item.title}}</a>
              </h5>
            </div>
            <div class="flex-shrink">
              <div class="del-icon f-right">
                <a (click)="cartService.removeCartProduct(item)" class="cursor-pointer">
                  <i class="fal fa-times"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="cart-price">
            <span class="ammount">{{item.orderQuantity}} <i class="fal fa-times"></i>&nbsp;</span>
            <span class="price">{{item.price!.toFixed(2)| currency:"EUR":"symbol"}}</span>
          </div>
        </div>

      </li>
    </ul>
    <div class="total-price d-flex justify-content-between mb-10">
      <span>Subtotal:</span>
      <span>{{(cartService.totalPriceQuantity().total).toFixed(2)| currency:"EUR":"symbol"}}</span>
    </div>
    <div class="checkout-link">
      <a routerLink="/shop/cart" class="os-btn" i18n>Carrello</a>
      <a class="os-btn os-btn-black" routerLink="/pages/checkout" *ngIf="hasShippingDelivery" i18n>Ordina</a>
    </div>
  </div>
</div>