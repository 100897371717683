import { Injectable, IterableDiffers } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DeliveryCompanyServiceModel, GuidServiceResponse, IOrderServiceModel, IProductDTO, LiteWebProductServiceModel, OrderAddressServiceModel, OrderServiceModel, PaymentServiceModel, ProductDTO, Service } from './api.service';
import { Title } from '@angular/platform-browser';
import { catchError, Observable, of, tap } from 'rxjs';
import { UtilsService } from './utils.service';
import { Router } from '@angular/router';
import { select } from '@ngneat/elf';
import { AuthStore } from '../store/auth.store';

const state = {
  cart_products: JSON.parse(localStorage['cart_products'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public orderQuantity: number = 1;
  public isCartOpen: boolean = false;
  order: IOrderServiceModel = {};
  userId: string | undefined;

  constructor(private authStore: AuthStore, private toastrService: ToastrService, private apiService: Service, protected utilsService: UtilsService, protected router: Router) {
    this.authStore.getUserData().pipe(
      tap(userData => {
        if (!!userData) {
          this.userId = userData?.userDetailId;
          this.order.applicationUserId = this.userId;
        }
      })
    ).subscribe();
  }


  public getCartProductCount(): number {

    var item = (state.cart_products as IProductDTO[]).length;
    if (item == 1) {
      var shippingPrice = (state.cart_products as IProductDTO[])[0].shippingPrice;
    }
    return item;
  }

  public getSingleShippingPrice(): number | undefined {

    var item = (state.cart_products as IProductDTO[]).length;
    if (item == 1) {
      return (state.cart_products as IProductDTO[])[0].shippingPrice;
    }
    return undefined;
  }

  public getSingleLocalShippingPrice(): number | undefined {

    var item = (state.cart_products as IProductDTO[]).length;
    if (item == 1) {
      return (state.cart_products as IProductDTO[])[0].localPrice;
    }
    return undefined;
  }

  public getCartProducts(): IProductDTO[] {
    return state.cart_products;
  }

  handleOpenCartSidebar() {
    this.isCartOpen = !this.isCartOpen
  }

  // add_cart_product
  addCartProduct(payload: IProductDTO) {
    const isExist = state.cart_products.some((i: IProductDTO) => i.id === payload.id);
    if (payload.quantity === 0) {
      this.toastrService.error(`Scorte esaurite ${payload.title}`);
    }
    else if (!isExist) {
      const newItem = {
        ...payload,
        orderQuantity: 1,
      };
      state.cart_products.push(newItem);
      this.toastrService.success(`${payload.title} aggiunto al carrello`);
    } else {
      state.cart_products.map((item: IProductDTO) => {
        if (item.id === payload.id) {
          if (typeof item.orderQuantity !== "undefined") {
            if (item.quantity! >= item.orderQuantity + this.orderQuantity) {
              item.orderQuantity =
                this.orderQuantity !== 1
                  ? this.orderQuantity + item.orderQuantity
                  : item.orderQuantity + 1;
              this.toastrService.success(`${this.orderQuantity} ${item.title} aggiunto al carrello`);
            } else {
              this.toastrService.success(`Quantità massima raggiunta per questo articolo!`);
              this.orderQuantity = 1;
            }
          }
        }
        return { ...item };
      });
    }
    localStorage.setItem("cart_products", JSON.stringify(state.cart_products));
  };

  public getShippingCost(): number {
    var itemPrice = this.totalPriceQuantity();
    var delivery_raw = localStorage.getItem("delivery");
    if (!!delivery_raw) {
      var delivery = JSON.parse(delivery_raw);
      return Number(delivery.price!);
    }
    return 0;
  }

  // total price quantity
  public totalPriceQuantity() {
    return state.cart_products.reduce(
      (cartTotal: { total: number; quantity: number }, cartItem: IProductDTO) => {
        const { price, orderQuantity } = cartItem;
        if (typeof orderQuantity !== "undefined") {
          // Calculate the item total without discount
          const itemTotal = price ?? 0 * orderQuantity;
          cartTotal.total += itemTotal;

          cartTotal.quantity += orderQuantity;
        }
        return cartTotal;
      },
      {
        total: 0,
        quantity: 0,
      }
    );
  };

  public totalPriceQuantityWithDelivery() {
    var itemPrice = this.totalPriceQuantity();
    var delivery_raw = localStorage.getItem("delivery");
    if (!!delivery_raw) {
      var delivery = JSON.parse(delivery_raw);
      var total = Number(itemPrice.total) + Number(delivery.price!);
      return total;
    } else {
      return Number(itemPrice.total);
    }
  };

  // quantity increment
  increment() {
    return this.orderQuantity = this.orderQuantity + 1;
  }

  // quantity decrement
  decrement() {
    return this.orderQuantity =
      this.orderQuantity > 1
        ? this.orderQuantity - 1
        : this.orderQuantity = 1;
  }

  // quantityDecrement
  quantityDecrement(payload: IProductDTO) {
    state.cart_products.map((item: IProductDTO) => {
      if (item.id === payload.id) {
        if (typeof item.orderQuantity !== "undefined") {
          if (item.orderQuantity > 1) {
            item.orderQuantity = item.orderQuantity - 1;
            this.toastrService.info(`Decrementata la quantità per ${item.title}`);
          }
        }
      }
      return { ...item };
    });
    localStorage.setItem("cart_products", JSON.stringify(state.cart_products));
  };

  // remover_cart_products
  removeCartProduct(payload: IProductDTO) {
    state.cart_products = state.cart_products.filter(
      (p: IProductDTO) => p.id !== payload.id
    );
    this.toastrService.error(`${payload.title} rimosso dal carrello`);
    localStorage.setItem("cart_products", JSON.stringify(state.cart_products));
  };

  // clear cart
  clear_cart(skipConfirmation: boolean = false) {
    if (skipConfirmation) {
      state.cart_products = [];
      this.order = {};
      this.cleanLocalStorage();

    } else {
      const confirmMsg = window.confirm(
        "Sei sicuro di voler svotare il carrello?"
      );
      if (confirmMsg) {
        state.cart_products = [];
        this.order = {};
      }
      this.cleanLocalStorage();
    }
  };

  cleanLocalStorage() {
    localStorage.setItem("cart_products", JSON.stringify(state.cart_products));
    localStorage.removeItem("delivery");
    localStorage.removeItem("payment");
  }

  // initialOrderQuantity
  initialOrderQuantity() {
    return this.orderQuantity = 1;
  };

  public placeOrder(orderPlacedFunc: (webOrder: string,) => void) {

    //setOrerCode
    var webOrder = this.generateUniqueNumber();
    this.setOrderCode(webOrder);
    //address invoice
    //address delivery
    this.setDeliveryOrder();
    this.setPaymentOrder();
    this.setProductsOrder()

    if (!!this.order.destinationAddress) {
      return this.apiService.placeCartOrder(this.order as OrderServiceModel).pipe(
        catchError(err => {
          console.log("placeOrder api service err")

          this.utilsService.catchAndShowError(err);
          return of();
        }),
        tap(result => {
          //f95b60f8-fea0-4f18-88ab-9ad876574ac5 id ordine
          //WEB_556897072869
          console.log("placeOrder api service tap")
          if (result.success) {
            this.clear_cart(true);
            this.toastrService.success("Ordine in atteda di convalida da parte di un operatore.");
            this.cleanLocalStorage();
            orderPlacedFunc(webOrder);
          } else {
            this.toastrService.error(result.message);
          }
          return of();
        })
      ).subscribe();

    } else {
      this.toastrService.error("Ti sei dimenticato di impostare la destinazione per la spedizione", "OPS..");
      return of();
    }

  }

  setProductsOrder() {
    var prod_raw = localStorage.getItem("cart_products");
    if (!!prod_raw) {
      var produtDTOs = JSON.parse(prod_raw) as ProductDTO[];
      this.order.previewProductList = [];
      produtDTOs.forEach(i => this.order.previewProductList!.push({
        productId: i.id!,
        title: i.title,
        itemCode: i.itemCode,
        quantity: i.orderQuantity,
        price: i.old_price,  //prezzo lordo!       

      } as LiteWebProductServiceModel));

    }
  }

  setOrderCode(orderCode: string) {
    this.order.orderCode = orderCode;
  }

  setInvoiceOrderAddress(iod: OrderAddressServiceModel) {
    this.order.destinationAddress = {
      firstName: iod.firstName,
      lastName: iod.lastName,
      fiscalCode: iod.fiscalCode,
      email: iod.email,
      fullAddress: iod.fullAddress,
      phoneNumber: iod.phoneNumber,
      deliveryNotes: iod.deliveryNotes,
      secondName: ''
    } as OrderAddressServiceModel;
  }

  setDestinationOrderAddress(doa: OrderAddressServiceModel) {
    this.order.destinationAddress = {
      firstName: doa.firstName,
      lastName: doa.lastName,
      fiscalCode: doa.fiscalCode,
      email: doa.email,
      fullAddress: doa.fullAddress,
      phoneNumber: doa.phoneNumber,
      deliveryNotes: doa.deliveryNotes,
      secondName: ''
    } as OrderAddressServiceModel;
  }

  setDeliveryOrder() {
    var delivery_raw = localStorage.getItem("delivery");
    if (!!delivery_raw) {
      var delivery = JSON.parse(delivery_raw) as DeliveryCompanyServiceModel;
      this.order.deliveryData = delivery;
    } else {
      this.toastrService.error("Ti sei dimenticato di selezionare la tipologia di spedizione", "OPS..");
    }
  }

  getDeliveryOrder(): DeliveryCompanyServiceModel | undefined {
    var delivery_raw = localStorage.getItem("delivery");

    if (!!delivery_raw) {
      var delivery = JSON.parse(delivery_raw) as DeliveryCompanyServiceModel;
      this.order.deliveryData = delivery;
      return delivery;
    }
    return undefined;
  }

  setPaymentOrder() {
    var payment_raw = localStorage.getItem("payment");
    if (!!payment_raw) {
      var payment = JSON.parse(payment_raw) as PaymentServiceModel;
      this.order.paymentMethod = payment;
    } else {
      this.toastrService.error("Ti sei dimenticato di selezionare la tipologia di pagamento", "OPS..");
    }
  }

  generateUniqueNumber(): string {
    const date = new Date();

    // Ottieni i vari componenti della data
    const year = date.getFullYear().toString().slice(-2); // Gli ultimi 2 caratteri dell'anno
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mesi da 0 a 11, quindi aggiungi 1
    const day = String(date.getDate()).padStart(2, '0'); // Giorno del mese
    const hours = String(date.getHours()).padStart(2, '0'); // Ore
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Minuti
    const seconds = String(date.getSeconds()).padStart(2, '0'); // Secondi

    return `WEB${year}${month}${day}T${hours}${minutes}${seconds}`;
  }
}
