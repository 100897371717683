<div class="row custom-row-1 px-3 pb-10">
    <div class="col-12" *ngFor="let item of deliveryCompanies; index as i">
        <app-delivery-company [deliveryCompany]="item" (onDeliveryChange)="handleDeliveryEvent($event)" />
    </div>
    <div class="pt-10" *ngIf="cartService.getCartProducts().length>1">
        <div class="alert alert-warning w-100">
            <h3 class="alert-heading">**</h3>
            <p class="h6">
                Faremo del nostro meglio per ridurre al minimo il numero di pacchi per gli articoli spediti tramite
                corriere. Vi informeremo sul costo totale e sulle modalità di spedizione. </p>
            <hr />
            <p>Pertanto, il costo indicato è solo approssimativo.</p>
        </div>
    </div>
</div>