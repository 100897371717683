<div class="row gap-1">
    <div class="d-flex flex-column" id="delivery-name" (click)="handleSelectCompanyDelivery($event, deliveryCompany!)">
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault"
                id="'delivery-name-${{deliveryCompany?.name}}'" [checked]="isSelected(deliveryCompany?.id!)">
            <label class="form-check-label" for="'delivery-name-${{deliveryCompany?.name}}'">
                {{deliveryCompany?.name}} <span *ngIf="!deliveryCompany?.name?.includes('Ritiro')">**&nbsp;</span> <span
                    class="badge theme-bg">{{deliveryCompany?.price|currency:"EUR"}}</span>
            </label>
        </div>
    </div>
    <div class="col" *ngIf="((isSelected(deliveryCompany?.id??'')||showNotes)&& !!deliveryCompany?.notes)">
        <div class="alert border-warning w-100 px-2">
            {{deliveryCompany?.notes}}
        </div>
    </div>
</div>