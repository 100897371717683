import { Component } from '@angular/core';
import { IPreviewQueryModel, IProductDTO } from 'src/app/shared/services/api.service';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-sale-of-products',
  templateUrl: './sale-of-products.component.html',
  styleUrls: ['./sale-of-products.component.scss']
})
export class SaleOfProductsComponent {

  public discount_products: IProductDTO[] = [];

  constructor(private productService: ProductService) {
    
  }

}
