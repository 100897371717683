import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentWarningComponent } from './appointment-warning/appointment-warning.component';
import { AppointmentRoutingModule } from './appointment-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppointmentStepperComponent } from './appointment-stepper/appointment-stepper.component';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentTakerComponent } from './appointment-taker/appointment-taker.component';
import { ItemsListComponent } from './items-list/items-list.component';



@NgModule({
  declarations: [
    AppointmentWarningComponent,
    AppointmentStepperComponent,
    AppointmentTakerComponent,
    ItemsListComponent,
  ],
  imports: [
    CommonModule,
    AppointmentRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepicker
  ]
})
export class AppointmentModule { }
