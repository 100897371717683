import { Component, Input } from '@angular/core';
import { IProductDTO } from 'src/app/shared/services/api.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-product-item-two',
  templateUrl: './product-item-two.component.html',
  styleUrls: ['./product-item-two.component.scss']
})
export class ProductItemTwoComponent {
  @Input() product!: IProductDTO;

  constructor(
    public cartService: CartService,
    public utilsService: UtilsService,
  ) {}

  // add to cart
  addToCart(item: IProductDTO) {
    this.cartService.addCartProduct(item);
  }
  // Function to check if an item is in the cart
  isItemInCart(item: IProductDTO): boolean {
    return this.cartService.getCartProducts().some((prd: IProductDTO) => prd.id === item.id);
  }
}
