<div *ngIf="!shop_category_2"
  [ngClass]="['banner__area', style_2 ? 'pt-95' : '', style_3 ? 'pt-20' : '', style_4 ? 'pt-30' : '']">
  <div [ngClass]="['container', style_3 ? 'custom-container' : '']">
    <div
      [ngClass]="[style_2 ? '' : (!style_3 && !style_4 ? 'banner__inner p-relative mt--95 z-index-1' : ''), style_4 ? 'banner__inner-2 p-relative' : '']">

      <div class="row  px-3">

        <div class="row">
          <div class="col-xl-12">
            <div class="section__title-wrapper text-center mb-25">
              <div class="section__title mb-10">
                <h2 i18n>Le nostre categorie</h2>
              </div>
            </div>
          </div>
        </div>
        <div class=" position-relative">
          <div class="d-flex flex-row gap-2 flex-nowrap row-gap-2 overflow-x-auto">
            <div class="d-flex flex-column " *ngFor="let item of categories">
              <div class="banner__item p-1 p-relative ">
                <div class="d-flex flex-column bg-body-secondary p-2 rounded bg-opacity-50"
                  style="height: 8rem;width: 12rem;">

                  <div class="m-auto transition-3">
                    <h5>
                      <a (click)="setCategoryAndGoShop($event, item.id!)"
                        [innerHTML]="item.name" class="text-wrap"></a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="position-absolute  top-50 end-0 translate-middle-y pe-1" style="margin-right: -0.75rem;">
            <i class="fas fa-chevron-circle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>