import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OrderAddressServiceModel } from '../../services/api.service';
import { AuthStore } from '../../store/auth.store';
import { retry, tap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TaxIdValidatorDirective } from '../directives/fiscalcode-taxid.directive';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss']
})
export class DelvieryAddressComponent implements OnInit {
  protected address_added: OrderAddressServiceModel = {} as OrderAddressServiceModel;
  protected address_invoice: OrderAddressServiceModel = {} as OrderAddressServiceModel;
  @Output() addressChanged: EventEmitter<OrderAddressServiceModel> = new EventEmitter<OrderAddressServiceModel>();
  @Output() addressInvoiceChanged: EventEmitter<OrderAddressServiceModel> = new EventEmitter<OrderAddressServiceModel>();

  public Isloading = false;
  public hasAddressInovice = false;
  public checkoutForm!: FormGroup;
  public invoiceForm!: FormGroup;

  /**
   *
   */

  constructor(protected authStore: AuthStore,) { this.Isloading = true; }


  ngOnInit() {

    this.authStore.getUserData().pipe(
      tap(data => {
        if (!!data) {
          //form vuoto
          this.checkoutForm = new FormGroup({
            firstName: new FormControl(data.name, Validators.required),
            lastName: new FormControl(data.surname, Validators.required),
            company: new FormControl(null),
            state: new FormControl("Italia"),
            address: new FormControl(null, Validators.required),
            zipCode: new FormControl(null, Validators.required),
            country: new FormControl(null, Validators.required),
            city: new FormControl(null, Validators.required),
            province: new FormControl(null, Validators.required),
            phone: new FormControl(null, [Validators.required, Validators.pattern(/^(\+39\s?)?3\d{2}[\s.-]?\d{6,7}$|^(\+33\s?)?[1-9]\d{1}[\s.-]?\d{2}[\s.-]?\d{2}[\s.-]?\d{2}$|^(\+49\s?)?\(?\d{3,4}\)?[\s.-]?\d{6,7}$|^(\+34\s?)?\d{3}[\s.-]?\d{3}[\s.-]?\d{3}$|^(\+44\s?)?\d{4}[\s.-]?\d{6}$|^(\+44\s?)?\d{3}[\s.-]?\d{3}[\s.-]?\d{4}$/)]),
            email: new FormControl(data.email, [Validators.required, Validators.email]),
            fiscalcode: new FormControl(null),
            taxid: new FormControl(null),
            orderNote: new FormControl(null),
          });
        } else {

          //form vuoto
          this.checkoutForm = new FormGroup({
            firstName: new FormControl(null, Validators.required),
            lastName: new FormControl(null, Validators.required),
            company: new FormControl(null),
            country: new FormControl(null, Validators.required),
            address: new FormControl(null, Validators.required),
            city: new FormControl(null, Validators.required),
            province: new FormControl(null, Validators.required),
            state: new FormControl("Italia"),
            zipCode: new FormControl(null, Validators.required),
            phone: new FormControl(null, [Validators.required, Validators.pattern(/^(\+39\s?)?3\d{2}[\s.-]?\d{6,7}$|^(\+33\s?)?[1-9]\d{1}[\s.-]?\d{2}[\s.-]?\d{2}[\s.-]?\d{2}$|^(\+49\s?)?\(?\d{3,4}\)?[\s.-]?\d{6,7}$|^(\+34\s?)?\d{3}[\s.-]?\d{3}[\s.-]?\d{3}$|^(\+44\s?)?\d{4}[\s.-]?\d{6}$|^(\+44\s?)?\d{3}[\s.-]?\d{3}[\s.-]?\d{4}$/)]),
            email: new FormControl(null, [Validators.required, Validators.email]),
            fiscalcode: new FormControl(null, [Validators.required]),
            orderNote: new FormControl(null),
          });
        }
        this.Isloading = false;
      })
    ).subscribe();

    this.invoiceForm = new FormGroup({
      firstName_i: new FormControl(null, Validators.required),
      lastName_i: new FormControl(null, Validators.required),
      company_i: new FormControl(null),
      country_i: new FormControl(null, Validators.required),
      address_i: new FormControl(null, Validators.required),
      city_i: new FormControl(null, Validators.required),
      province_i: new FormControl(null, Validators.required),
      state_i: new FormControl("Italia"),
      zipCode_i: new FormControl(null, Validators.required),
      phone: new FormControl(null, [Validators.required, Validators.pattern(/^(\+39\s?)?3\d{2}[\s.-]?\d{6,7}$|^(\+33\s?)?[1-9]\d{1}[\s.-]?\d{2}[\s.-]?\d{2}[\s.-]?\d{2}$|^(\+49\s?)?\(?\d{3,4}\)?[\s.-]?\d{6,7}$|^(\+34\s?)?\d{3}[\s.-]?\d{3}[\s.-]?\d{3}$|^(\+44\s?)?\d{4}[\s.-]?\d{6}$|^(\+44\s?)?\d{3}[\s.-]?\d{3}[\s.-]?\d{4}$/)]),
      email_i: new FormControl(null, [Validators.required, Validators.email]),
      fiscalcode_i: new FormControl(null, [Validators.required]),
      orderNote_i: new FormControl(null),
    });
  }

  showInvoiceAddress(evt: Event) {
    this.hasAddressInovice = !this.hasAddressInovice;
  }

  notifyParent(event: Event): void {
    this.address_added.fullAddress = (this.address?.value + ', ' + this.zipCode?.value + ', ' + this.country?.value + ', '+ this.city?.value + ', '+ this.province?.value +' ' + this.state?.value);
    this.address_added.email = this.email?.value;
    this.address_added.fiscalCode = this.fiscalcode?.value;
    this.address_added.lastName = this.lastName?.value;
    this.address_added.firstName = this.firstName?.value;
    this.address_added.deliveryNotes = this.orderNote?.value;
    this.address_added.phoneNumber = this.phone?.value;
    this.address_invoice.deliveryNotes = this.orderNote?.value ?? '';
   this.addressChanged.emit(this.address_added);
  }


  notifyInvoice(event: Event): void {
    if (this.invoiceForm.valid) {
      this.address_invoice.fullAddress?.concat(this.address_i?.value + ', ' + this.zipCode_i?.value + ', ' +  this.country_i?.value + ', '+ this.city_i?.value + ', '+ this.province_i?.value +', ' + this.state_i?.value);
      this.address_invoice.email = this.email_i?.value;
      this.address_invoice.fiscalCode = this.fiscalcode_i?.value;
      this.address_invoice.lastName = this.lastName_i?.value;
      this.address_invoice.firstName = this.firstName_i?.value;
      this.address_invoice.deliveryNotes = this.orderNote_i?.value;
      this.address_invoice.phoneNumber = this.phone_i?.value;
      this.address_invoice.deliveryNotes = this.company_i?.value ?? '';

      this.addressInvoiceChanged.emit(this.address_invoice);
    }
  }
  get firstName() {
    return this.checkoutForm.get('firstName');
  }
  get lastName() {
    return this.checkoutForm.get('lastName');
  }
  get company() {
    return this.checkoutForm.get('company');
  }
  get country() {
    return this.checkoutForm.get('country');
  }
  get address() {
    return this.checkoutForm.get('address');
  }
  get city() {
    return this.checkoutForm.get('city');
  }
  get state() {
    return this.checkoutForm.get('state');
  }

  get zipCode() {
    return this.checkoutForm.get('zipCode');
  }
  get phone() {
    return this.checkoutForm.get('phone');
  }
  get orderNote() {

    return this.checkoutForm.get('orderNote');
  }
  get email() {
    return this.checkoutForm.get('email');
  }
  get fiscalcode() {
    return this.checkoutForm.get('fiscalcode');
  }
  get province() {
    return this.checkoutForm.get('province');
  }
  //inovice data

  get firstName_i() {
    return this.invoiceForm.get('firstName_i');
  }
  get lastName_i() {

    return this.invoiceForm.get('lastName_i');
  }
  get company_i() {

    return this.invoiceForm.get('company_i');
  }
  get country_i() {
    return this.invoiceForm.get('country_i');
  }
  get address_i() {
    return this.invoiceForm.get('address_i');
  }
  get city_i() {
    return this.invoiceForm.get('city_i');
  }
  get state_i() {
    return this.invoiceForm.get('state_i');
  }
  get zipCode_i() {
    return this.invoiceForm.get('zipCode_i');
  }
  get phone_i() {

    return this.invoiceForm.get('phone_i');
  }
  get orderNote_i() {

    return this.invoiceForm.get('orderNote_i');
  }
  get email_i() {

    return this.invoiceForm.get('email_i');
  }
  get fiscalcode_i() {
    return this.invoiceForm.get('fiscalcode_i');
  }
  get province_i(){
    return this.invoiceForm.get("province_i");
  }
}