
<!-- header start -->
<app-header-one [transparent]="false" [white_bg]="true"></app-header-one>
<!-- header end -->

<main>

  <!-- breadcrumb start -->
  <app-breadcrumb-one title="Carrello" subtitle="Carrello"></app-breadcrumb-one>
  <!-- breadcrumb end -->
  <!-- Cart Area Strat-->
  <section class="cart-area pt-25 pb-25  bg-white">
    <div class="container">
      <div class="row mb-3 bg-body-secondary bg-opacity-25 pt-10 pb-10 rounded">
        <div class="input-group">
          <input type="search" class="form-control" (input)="fillOrderCode($event)"
            placeholder="Cerchi un ordine? Insersci qui il codice" i18n-placeholder="Inserisci il codice del tuo ordine"
            aria-label="Controlla il tuo ordine" aria-describedby="check-order">
          <button class="btn btn-outline-secondary" type="button" id="check-order"
            (click)="searchOrderCode($event)">Vedi Ordine</button>
        </div>
      </div>

      <div *ngIf="cartService.getCartProducts().length === 0" class="text-center pt-50">
        <h3 i18n>Non ci sono articoli</h3>
        <a routerLink="/shop" class="os-btn os-btn-black" i18n>Continua lo shopping</a>
      </div>

      <div *ngIf="cartService.getCartProducts().length > 0" class="row">
        <div class="col-12">
          <div class="table-content table-responsive">
            <table class="table table-striped table-hover table-sm  align-middle">
              <thead>
                <tr>
                  <th class="product-thumbnail" i18n>Media</th>
                  <th class="cart-product-name" i18n>Prodotto</th>
                  <th class="product-price" i18n>Prezzo singolo</th>
                  <th class="product-quantity" i18n>Spedizione Corriere</th>
                  <th class="product-remove" i18n>Elimina</th>
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let item of cartService.getCartProducts()">
                  <td class="product-thumbnail">
                    <a [routerLink]="['/shop/shop-details', item.id]">
                      <img [src]="item.img" alt="product-img">
                    </a>
                  </td>
                  <td class="product-name">
                    <a [routerLink]="['/shop/shop-details', item.id]">{{item.title}}</a>
                  </td>
                  <td class="product-price"><span
                      class="amount">{{item.price!.toFixed(2)|currency:"EUR":"symbol"}}</span></td>
                  <td class="product-quantity">
                    <span class="amount"> 
                       {{item.canBeWebShipped?((item.shippingPrice??0).toFixed(2)|currency:"EUR":"symbol"):'NON Possibile'}}
                      </span>
                  </td>
                  <td class="product-subtotal">
                    <span class="amount">
                      {{(item.price! + (item.shippingPrice??0)).toFixed(2)|currency:"EUR":"symbol"}}
                    </span>
                  </td>
                  <td class="product-remove">
                    <a (click)="cartService.removeCartProduct(item)" class="cursor-pointer">
                      <i class="fa fa-times"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row justify-content-center">
            <div class="coupon-all">
              <div class="coupon2">
                <button (click)="cartService.clear_cart()" class="os-btn os-btn-black" name="update_cart" type="submit">
                  Svuota carrello
                </button>
              </div>
            </div>

          </div>

          <div class="d-flex flex-row pt-25">
            <app-delivery (onDeliveryChange)="handleShippingCost($event)"></app-delivery>
          </div>

          <div class="d-flex flex-row pt-25">
            <app-payments></app-payments>
          </div>

          <div class="row">
            <div class="col-md-5 ml-auto">
              <div class="cart-page-total">
                <h2 i18n>Riepilogo</h2>
                <ul class="mb-20">
                  <li i18n>
                    Spedizione
                    <span>{{(cartService.getShippingCost()).toFixed(2)|currency:"EUR":"symbol"}}</span>
                  </li>
                  <li i18n>
                    Subtotal
                    <span>{{(cartService.totalPriceQuantity().total).toFixed(2)|currency:"EUR":"symbol"}}</span>
                  </li>
                  <li i18n>Total
                    <span>{{((cartService.totalPriceQuantityWithDelivery())).toFixed(2)|currency:"EUR":"symbol"}}</span>
                  </li>
                </ul>
                <div class="row justify-content-center align-items-center px-3">

                  <a class="os-btn" routerLink="/pages/checkout" i18n>Procedi all'ordine</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Cart Area End-->
</main>