<div class="d-flex flex-column row-gap-2" id="appointment-banner-container">

    <div
        class="d-flex flex-md-row flex-column justify-content-center align-items-center bg-bravo row-gap-2 gap-2 p-2 rounded">
        <div class="d-flex flex-column flex-md-row flex-nowrap row-gap-2 gap-2">

            <h5 class="mb-0 text-center text-white" i18n>Hai bisogno di un appuntamento?</h5>
        </div>
        <button class="btn os-btn btn-lg rounded" (click)="takeAppointment($event)"  i18n>
            Prenota ora
        </button>       
    </div>