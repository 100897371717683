import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { AuthStore } from '../store/auth.store';
import { JwtService } from './jwt.service';
import { Service, TokenModel } from './api.service';
import { AuthProps } from '../data/auth-props';
import { select } from '@ngneat/elf';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {
  token: string | null | undefined;
  constructor(private encryptDecryptService: EncryptDecryptService, protected authStore: AuthStore, protected jwtService: JwtService, protected apiService:Service) {
    authStore.getTokenModel().subscribe(t => this.token = t);
  }

  // If you want to some exclude api call from Encryption then add here like that.
  // environment.basUrl is your API URL
  excludeURLList = [
    //environment.apiURL + '/api/Common/commonFileuploaddata',
  ];

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    let exludeFound = this.excludeURLList.filter((element) => {
      return req.url.includes(element);
    });
    //adding auth token in header 
    if (!!this.token) {
      var isTokenInvalid = this.jwtService.isExpiredToken(this.token);
      var authFormLs = localStorage.getItem("auth");               
      if (isTokenInvalid&& !!authFormLs) {
        var auth = JSON.parse(authFormLs) as AuthProps;

        this.apiService.refreshToken({refreshToken: auth.refreshToken, token:auth.token}as TokenModel).pipe(
          select(e=> {
            if(e.success){

              this.token= e?.data?.token!;
              this.authStore.SetDataInStore(
                e?.data?.token!,
                e?.data?.refreshToken!,
              );
            }
          })
        ).subscribe()
      } 

        req = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${this.token}`),
        });
      
    }

    req = req.clone({
      headers: req.headers.set('x-key', `ae35f1b6-2ac2-43be-a2f8-49fd6eda3c78`),
    });
    // We have Encrypt the GET and POST call before pass payload to API
    if (!(exludeFound && exludeFound.length > 0)) {
      if (req.method == 'GET') {
        if (req.url.indexOf('?') > 0) {
          let encriptURL =
            req.url.substr(0, req.url.indexOf('?') + 1) +
            this.encryptDecryptService.encryptUsingAES256(
              req.url.substr(req.url.indexOf('?') + 1, req.url.length)
            );
          const cloneReq = req.clone({
            url: encriptURL,
            withCredentials: true,
          });
          return next.handle(cloneReq);
        }
        return next.handle(req);
      } else if (req.method == 'POST') {
        if (req.body || req.body.length > 0) {
          const cloneReq = req.clone({
            body: this.encryptDecryptService.encryptUsingAES256(req.body),
            withCredentials: true,
          });
          return next.handle(cloneReq);
        }
        let data = req.body as FormData;
        return next.handle(req);
      }
    }


    return next.handle(req);
  }
}
