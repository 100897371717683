
<!-- header start -->
<app-header-one [transparent]="false" [white_bg]="true"></app-header-one>
<!-- header end -->

<main>

  <!-- breadcrumb start -->
  <app-breadcrumb-one title="404" subtitle="404"></app-breadcrumb-one>
  <!-- breadcrumb end -->

  <!-- 404 area start -->
  <section class="error__area pt-60 pb-100  bg-white">
    <div class="container">
        <div class="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2">
            <div class="error__content text-center">
                <div class="error__number">
                    <h1>404</h1>
                </div>
                <span i18n>Ops...</span>
                <h2 i18n>Sembra non ci sia nulla qui!</h2>
                <p i18n>La pagina cercata non è stata trovata, forse non più disponibile.</p>

                <div class="error__search">
                    <a routerLink="/" class="os-btn os-btn-3 os-btn-black" i18n>Homepage</a>
                </div>
            </div>
        </div>
    </div>
</section>
  <!-- 404 area end -->
</main>

