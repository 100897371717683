import { Component } from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss']
})
export class ProductModalComponent {

  constructor(public utilsService:UtilsService){}

onCloseModal(evn:Event){
 var el= document.getElementById("close_modal");
 if(!!el){
  el.click();
 } 
}

}
