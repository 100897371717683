import { Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ShopModule } from './shop/shop.module';
import { HttpClientModule } from '@angular/common/http';
import { API_BASE_URL } from './shared/services/api.service';
import { environment } from 'src/environments/environment';
import { AuthInterceptorService } from './shared/services/auth-interceptor.service';
import { AuthStore } from './shared/store/auth.store';
import { AppointmentModule } from './appointment/appointment.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID } from '@angular/core';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    ShopModule,
    AppointmentModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: true,
      enableHtml: true,
      positionClass: 'toast-top-center'
    }),
    
    NgxGoogleAnalyticsModule.forRoot( environment.googleAnalytics),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [{ provide: API_BASE_URL, useValue: environment.apiURL },
  { provide: AuthStore },
  { provide: LOCALE_ID, useValue: 'it-IT' },
    httpInterceptorProviders,],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(@Inject(LOCALE_ID) locale: string) {

  }
};
