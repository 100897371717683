<div class="product__wrapper mb-10">
    <div class="product__thumb">
        <img [src]="'./../../../assets/img/logo/logo.svg'" alt="product-img" [style]="customStyle">
    </div>
    <div class="product__content p-relative placeholder-glow">
        <div class="product__content-inner">
            <h4 class="placeholder-glow">
                <span class="col-8 placeholder"></span>
            </h4>
        </div>
        <span class="col-5 placeholder"></span>
    </div>
</div>