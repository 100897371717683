import { Component, Input } from '@angular/core';
import { IProductTagServiceModel } from '../../services/api.service';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
  @Input() selected: boolean = false;
  @Input() tag: IProductTagServiceModel ={};
}
