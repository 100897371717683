
<!-- header start -->
<app-header-one [transparent]="false" [white_bg]="true"></app-header-one>
<!-- header end -->

<main>

  <!-- breadcrumb start -->
  <app-breadcrumb-one title="I nostri prodotti" subtitle="Shop"></app-breadcrumb-one>
  <!-- breadcrumb end -->

  <!-- shop area start -->
  <app-shop-area></app-shop-area>
  <!-- shop area end -->
</main>
