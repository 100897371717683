import { Component } from '@angular/core';
import { CartService } from 'src/app/shared/services/cart.service';

@Component({
  selector: 'app-mini-cart',
  templateUrl: './mini-cart.component.html',
  styleUrls: ['./mini-cart.component.scss']
})
export class MiniCartComponent {

  protected hasShippingDelivery: boolean = false;
  constructor(public cartService: CartService) {

    var shoppingcart_delivery = localStorage.getItem("delivery");
    if (!!shoppingcart_delivery) {
      this.hasShippingDelivery = true;
    }
  }
}
