<!-- header start -->
<app-header-one [transparent]="false" [white_bg]="false"></app-header-one>
<!-- header end -->

<main>
    <app-breadcrumb-one title="Appuntamento Merce" subtitle="Appuntamento"></app-breadcrumb-one>

    <section class="container pt-10 pb-25">
        <div class="container">
            <div class="d-flex flex-column row-gap-2 fap-2">
                <div class="d-flex flex-column">
                    <div id="appointment-data"></div>
                    <app-loader [IsLoading]="isLoading" />
                </div>
            </div>
            <div class="d-flex flex-row justify-content-center bg-theme-2 row-gap-2 gap-2 p-2 rounded mt-2"
                *ngIf="!isLoadingUserdata">
                <button class="os-btn btn-lg rounded bg-white"
                    *ngIf="(isLoggedin&&!hasAppointment); else notAllowed" (click)="toStepper($event)"
                    i18n>Prosegui</button>

                <ng-template #notAllowed>

                        <button class="btn btn-secondary text-white btn-lg rounded disabled"
                            *ngIf="hasAppointment">Appuntamento già preso questo mese: {{appointmentDate}}</button>

                            <div class="btn btn-group" *ngIf="!hasAppointment" >
                                <button class="os-btn bg-white btn btn-lg rounded" (click)="goLogin($event)"
                               i18n>Successivo</button>
                            </div>
                  
                </ng-template>
            </div>
        </div>
    </section>
</main>