import { Component, Input, OnInit } from '@angular/core';
import { PaymentServiceModel, Service } from '../../services/api.service';
import { tap } from 'rxjs';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  protected payments: PaymentServiceModel[] = [];
  protected bankAccount: { paymentName: string, bankAddress: string, ownerAccount: string, companyName: string, companyBank: string, iban: string, } | undefined;
  protected isDefaulPaymentLoading: boolean = false;
  protected isPaymentsLoading: boolean = false;

  @Input() payment: PaymentServiceModel | undefined;
  /**
   *
   */
  constructor(protected apiStore: Service, protected cartService: CartService) {
    this.isDefaulPaymentLoading = true;
  }

  ngOnInit(): void {
    //this.apiStore.getAllPayments(true).pipe().subscribe();
    if (!!this.payment) {
      var data = JSON.parse(this.payment.serializedPayment!);
      this.bankAccount = {
        paymentName: "Bonifico Bancario",
        bankAddress: data.BankAddress!,
        ownerAccount: data.AccountOwner,
        companyBank: data.CompanyBank,
        companyName: data.CompanyName,
        iban: data.Iban
      }
      this.isDefaulPaymentLoading = false;
    } else {

      this.apiStore.getDefaultPayment().pipe(
        tap(e => {
          var payment = JSON.parse(e.data!.serializedPayment!);
          this.bankAccount = {
            paymentName: "Bonifico Bancario",
            bankAddress: payment.BankAddress!,
            ownerAccount: payment.AccountOwner,
            companyBank: payment.CompanyBank,
            companyName: payment.CompanyName,
            iban: payment.Iban
          }
          this.isDefaulPaymentLoading = false;
          localStorage.setItem("payment", JSON.stringify(e.data));
        })
      ).subscribe();

    }
  }

  copyText(evt: Event, id: string) {
    var copyText = document.getElementById(id) as HTMLInputElement;
    if (copyText) {

      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);
      alert("Iban copiato con successo!");
    }
  }
}
