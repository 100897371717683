<section class="sale__area pb-100">
  <div class="container">
      <div class="row">
          <div class="col-xl-12">
              <div class="section__title-wrapper text-center mb-55">
                  <div class="section__title mb-10">
                      <h2 i18n>In Promozione</h2>
                  </div>
                  <div class="section__sub-title">
                      <p>Prodotti ad un prezzo d'occasione!</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-xl-12">
              <div class="sale__area-slider">
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">
                  <div *ngFor="let item of discount_products" class="col sale__item">
                      <app-product-item [product]="item"></app-product-item>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</section>
