<div class="d-flex flex-column w-100 border m-2 p-4 rounded row-gap-2 gap-2 bg-theme-2" id="appointment-summary">
    <div class="d-flex flex-md-row flex-column justify-content-center aligb-items-center row-gap-2 gap-2 ">
        <div class="fs-4 text-theme d-flex flex-column flex-md-row row-gap-2 gap-2">
           <span>{{appointment.startTime|date:"dd MMMM yyyy"}}</span>
           <span>{{appointment.startTime|date:"alle HH:mm"}}</span>
        </div>
    </div>
    <div class="d-flex flex-md-row flex-column justify-content-center">
        <div class="d-flex flex-column justify-content-center text-white">
            <div class="d-flex flex-md-row flex-column flex-wrap row-gap-2 gap-2">
                {{appointment.clientName}}
                {{appointment.clientSurname}}
            </div>
            <div class="d-flex flex-nowrap flex-row gap-2" *ngIf="!!appointment.cardCode">
                <div i18n>Tessera</div>
                <span>{{appointment.cardCode}} </span>
            </div>
            <div class="d-flex flex-row w-100 text-theme justify-content-center" i18n>
                <em>Vi preghiamo di arrivare in negozio qualche minuto prima, vi ringraziamo della collaborazione.</em>
            </div>
        </div>
        <div class="d-flex flex-row justify-content-center">
            <app-logo></app-logo>
        </div>
    </div>
</div>