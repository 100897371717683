export type ISocial =  {
  link: string;
  icon: string;
  name: string;
}

const social_links:ISocial[] = [
  {
    link: "https://www.facebook.com/usatomaniaimperia/",
    icon: "fab fa-facebook",
    name: "Facebook",
  },
   {
    link: "https://www.instagram.com/usatomaniaim/",
    icon: "fab fa-instagram-square",
    name: "Instagram",
  },
]

export default social_links;
