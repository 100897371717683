import { Directive } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appTaxIdValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: TaxIdValidatorDirective, multi: true }]
})
export class TaxIdValidatorDirective implements Validator {
 
    static atLeastOneRequired(): ValidatorFn {
        return (control: AbstractControl): {[key: string]: any} | null => {
          const fiscalCode = control.get('fiscalCode')?.value;
          const taxId = control.get('taxId')?.value;
    
          if (!fiscalCode && !taxId) {
            return { 'atLeastOneRequired': true };
          }
    
          return null;
        };
      }
  validate(control: AbstractControl): ValidationErrors | null {
    const fiscalCode = control.get('fiscalCode')?.value;
    const taxId = control.get('taxId')?.value;

    if (!fiscalCode && !taxId) {
      return { 'atLeastOneRequired': true };
    }

    return null;
  }
}
