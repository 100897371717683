import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { CategoryServiceModel, Service } from 'src/app/shared/services/api.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss'],
})
export class CategoryFilterComponent {

  @Output() onSelectCategory: EventEmitter<any> = new EventEmitter<any>();

  protected categories: CategoryServiceModel[] | undefined;

  constructor(
    public utilsService: UtilsService,
    public apiService: Service,
    protected router: Router
  ) {
    var ctg = localStorage.getItem("wctg")
    if (!!ctg) {
      this.categories = JSON.parse(ctg);
      this.categories = this.categories?.sort((a, b) => {
        const nameA = a.name!.toLowerCase();
        const nameB = b.name!.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })

    }
  }

  handleCategoryIsSelected(categoryId: number): boolean {
    return this.utilsService.isCategorySelected(categoryId);
  }

  public handleParentCategory(evn: Event, ctgid: number): void {

    this.utilsService.updateCategoryList(ctgid);

    this.onSelectCategory.emit();
  }
}
