<section class="footer__area footer-bg" [class.box-m-15]="box_style">
  <div class="footer__top pt-100 pb-60">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-12">
          <div class="footer__widget mb-30">
            <div class="footer__widget-title mb-25">
              <a routerLink="/home/home-style-1">
                <img src="/assets/img/logo/logo.png" alt="logo" id="logo">
              </a>
            </div>
            <div class=" footer__widget-content">
              <p i18n id="{{footer_company_presentation}}"></p>
              <div class="footer__contact" *ngIf="!!comapanyData">
                <ul>
                  <li>
                    <div class="icon">
                      <i class="fal fa-map-marker-alt"></i>
                    </div>
                    <div class="text-white">
                      <span i18n>{{comapanyData.fullAddress}}</span>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fal fa-envelope-open-text"></i>
                    </div>
                    <div class="text-white">
                      <a href=`mailto:${comapanyData.email}` i18n>{{comapanyData.email}}</a>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fal fa-phone-alt"></i>
                    </div>
                    <div class="text-white">
                      <a [attr.href]="'tel:'+ comapanyData.mobile" i18n>{{comapanyData.mobile}}</a>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="fal fa-phone-alt"></i>
                    </div>
                    <div class="text-white">
                      <a [attr.href]="'tel:'+ comapanyData.phone" i18n>{{comapanyData.phone}}</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-12">
          <div class="footer__widget mb-30">
            <div class="footer__widget-title">
              <h5 i18n>Informazioni</h5>
            </div>
            <div class="footer__widget-content">
              <div class="footer__links">
                <ul>
                  <li><a href="/pages/static/a7fa6797-a7a3-47ba-98dd-bb0e61112079" i18n>Chi Siamo</a></li>
                  <li><a href="/pages/static/edd5f924-f3be-4a0f-b5a9-eed8f393bf03" i18n>Termini & Condizioni</a></li>
                  <li><a href="/pages/contact" i18n>Contattaci</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer__bottom">
    <div class="container">
      <div class="d-flex flex-md-row flex-column">
        <div class="d-flex">
          <div class="footer__copyright">
            <p>Copyright © Usatomania
            </p>
          </div>
        </div>
          <div class="d-flex flex-row flex-nowrap">

            <div class="footer__social f-right">
              <ul>
                <li *ngFor="let item of social_links">
                  <a [href]="item.link" target="_blank"><i [class]="item.icon"></i></a>
                </li>
              </ul>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>