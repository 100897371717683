import { JwtService } from '../services/jwt.service';
import { Claims } from './claims';

/**
 * Classe contente le props che definisco le caratteristiche di un utente loggato
 */
export class AuthProfile {
    /**
     * Consente di inzializzare le props di un utente autenticato
     * @param jwtService servizio per la lettura dei claims presenti nel token
     * @param token token di autenticazione
     */
    constructor(jwtService: JwtService, token: string) {
        this.userId = jwtService.getClaim(token, Claims.nameidentifier);
        this.userDetailId = jwtService.getClaim(token, Claims.userDetailId);
        this.name = jwtService.getClaim(token, Claims.userName);
        this.surname = jwtService.getClaim(token, Claims.userSurname);
        this.email = jwtService.getClaim(token, Claims.email);
        this.phone = jwtService.getClaim(token, Claims.phone);
        this.cardCode = jwtService.getClaim(token, Claims.cardCode);
        this.roles = jwtService.getClaim(token, Claims.roles);      
        this.fiscalcode = jwtService.getClaim(token, Claims.fiscalcode);
    }

    /*Props utente*/
    /** Identificativo dettaglio utente*/
    userId: string | undefined;

    /** Identificativo dettaglio utente*/
    userDetailId: string | undefined;
    /** cognome */
    surname: string | undefined;
    /** nome */
    name: string | undefined;
    /** email */
    email: string | undefined;
    /** telefono */
    phone: string | undefined;
    /** codice tessera */
    cardCode: string | undefined;
    /** lista ruoli utente */
    roles: string[] | undefined;
    /** indica se l'utente ha già preso un appuntamento per questo mese */
    hasTakenAppointment: boolean = false;

    /** indica la data dell'appuntamento preso */
    appointmentDate: string | undefined;

    /** identificativo univoco dell'appuntamento */
    appointmentId: string = '';
    fiscalcode: string = '';
}
