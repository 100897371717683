 ///<reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import  localeIt  from '@angular/common/locales/it';

registerLocaleData(localeIt, 'it-IT')

platformBrowserDynamic([{provide: LOCALE_ID, useValue: 'it-IT'}]).bootstrapModule(AppModule,{
  providers: [{provide: LOCALE_ID, useValue: 'it-IT', }]
})
.catch(err =>
   console.error(err)
);
