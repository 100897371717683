<div class="pb-20">
    <!-- router outlet start -->
    <router-outlet></router-outlet>
    <!-- router outlet end -->
    <!-- back to top start -->
    <app-back-to-top></app-back-to-top>
    <!-- back to top end -->

    <!-- product modal start -->
    <app-product-modal></app-product-modal>
    <!-- product modal end -->

    <!-- appointment confirmation modal start -->
    <app-appointment-confirmation-modal></app-appointment-confirmation-modal>
    <!-- appointment confirmation modal end -->
</div>

<ng-container *ngIf="afterRender" >    
    <app-footer-one></app-footer-one>
</ng-container>
