import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeliveryCompanyServiceModel, Service } from '../../services/api.service';
import { UtilsService } from '../../services/utils.service';
import { catchError, of, tap } from 'rxjs';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {
  @Input() WebShipping: boolean = false;
  @Output() onDeliveryChange: EventEmitter<DeliveryCompanyServiceModel> = new EventEmitter<DeliveryCompanyServiceModel>();

  protected isLoading: boolean = false;
  protected deliveryCompanies: DeliveryCompanyServiceModel[] = [];


  /**
   *
   */
  constructor(protected apiService: Service, protected utilsService: UtilsService, protected cartService: CartService) {
    this.isLoading = true;

  }

  ngOnInit(): void {
    this.apiService.getAllDeliveryCompanies()
      .pipe(
        catchError(err => {
          this.utilsService.catchAndShowError(err);
          return of();
        }),
        tap(result => {
          if (result.success) {
            result.data?.sort((c1, c2) => c1.name!.localeCompare(c2.name!)).forEach(c =>
              this.deliveryCompanies.push(c)
            );
          } else {
            //qualcosa è andato storto
          }
          var shippingPrice = this.cartService.getSingleShippingPrice();
          var localPrice = this.cartService.getSingleLocalShippingPrice();
          
          if (!!shippingPrice && shippingPrice > 0) {
            var gls = this.deliveryCompanies.filter(e => e.name == "GLS")[0];
            gls.price = shippingPrice;
            localStorage.setItem("delivery", JSON.stringify(gls));
          }

          if (!!localPrice && localPrice > 0) {
            var local = this.deliveryCompanies.filter(e => e.name == "Trasportatore locale")[0];
            local.price = localPrice;
            localStorage.setItem("delivery", JSON.stringify(local));
          }

          this.isLoading = false;
        })
      )
      .subscribe();
  }
  handleDeliveryEvent(delivery: DeliveryCompanyServiceModel) { this.onDeliveryChange.emit(delivery); }
}
