import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppointmentWarningComponent } from './appointment-warning/appointment-warning.component';
import { AppointmentStepperComponent } from './appointment-stepper/appointment-stepper.component';

const routes: Routes = [
  {
    path: 'get-appointment',
    component: AppointmentWarningComponent,
    title: 'Appuntamento Merce',
  }, 
  {
    path: 'get-appointment-stepper',
    component: AppointmentStepperComponent,
    title: 'Appuntamento Merce',
  }, 
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppointmentRoutingModule { }
