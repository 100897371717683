import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DeliveryCompanyServiceModel } from 'src/app/shared/services/api.service';
import { CartService } from 'src/app/shared/services/cart.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {

  orderCode: string = '';
  protected shipCost: number = 0;

  constructor(public cartService: CartService, protected router: Router) { }

  searchOrderCode(event: any) {
    if (!!this.orderCode) {
      this.router.navigate(['/shop/orders-summary/' + this.orderCode]);
    } else {
      alert("Se vuoi vedere i dettagli di un ordine, devi inserire il codide dell'ordine!");
    }
  }

  fillOrderCode(event: any) {
    this.orderCode = event.target?.value ?? '';
  }

  handleShippingCost(delivery: DeliveryCompanyServiceModel) {
    this.shipCost = delivery.price!
  }
}
