import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, of, tap } from 'rxjs';
import category_data from 'src/app/shared/data/category-data';
import { CategoryServiceModel, Service } from 'src/app/shared/services/api.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ICategoryType } from 'src/app/shared/types/category-d-t';

@Component({
  selector: 'app-shop-category',
  templateUrl: './shop-category.component.html',
  styleUrls: ['./shop-category.component.scss']
})
export class ShopCategoryComponent implements OnInit {
  @Input() style_2: Boolean = false;
  @Input() style_3: Boolean = false;
  @Input() style_4: Boolean = false;
  @Input() shop_category_2: Boolean = false;

  public category_data: ICategoryType[] = category_data;
  protected isLoading: boolean = false;
  protected categories: CategoryServiceModel[] | undefined;

  constructor(public utilsService: UtilsService, protected apiService: Service, protected router: Router) {
    this.isLoading = true;
  }

  ngOnInit(): void {
    this.apiService.getAllWebsiteProductCategory(true).pipe(
      catchError(err => {
        this.utilsService.catchAndShowError(err);
        return of();
      }),
      tap(results => {
        if (results.success) {

          this.categories = [];
          results.data!.filter(e => e.name).forEach(e => this.categories?.push(e));
        } else {

        }
        this.isLoading = false;
      }),).subscribe()
  }

  setCategoryAndGoShop(event: Event, ctg: number) {
    this.utilsService.updateCategoryList(ctg);
    this.router.navigate(["/shop"])
  }

}
