<section class="footer__area grey-bg p-relative">
  <div class="footer__top pt-75 pb-60">
      <div class="container">
          <div class="row">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div class="footer__widget mb-30">
                      <div class="footer__widget-title footer__widget-title-2 mb-25">
                          <h5>My Account</h5>
                      </div>
                      <div class="footer__widget-content">
                          <div class="footer__links footer__links-2">
                              <ul>
                                  <li><a href="#">My Account</a></li>
                                  <li><a href="#">Checkout</a></li>
                                  <li><a href="#">Shopping Cart</a></li>
                                  <li><a href="#">Wishlist</a></li>
                                  <li><a href="#">Custom Link</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div class="footer__widget mb-30">
                      <div class="footer__widget-title footer__widget-title-2 mb-25">
                          <h5>Quick Links</h5>
                      </div>
                      <div class="footer__widget-content">
                          <div class="footer__links footer__links-2">
                              <ul>
                                  <li><a href="#">Store Location</a></li>
                                  <li><a href="#"> My Account</a></li>
                                  <li><a href="#">Orders Tracking</a></li>
                                  <li><a href="#">Size Guide</a></li>
                                  <li><a href="#">FAQs</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div class="footer__widget mb-30">
                      <div class="footer__widget-title footer__widget-title-2 mb-25">
                          <h5>information</h5>
                      </div>
                      <div class="footer__widget-content">
                          <div class="footer__links footer__links-2">
                              <ul>
                                  <li><a href="#">About Us</a></li>
                                  <li><a href="#">Careers</a></li>
                                  <li><a href="#">Delivery Inforamtion</a></li>
                                  <li><a href="#">Privacy Policy</a></li>
                                  <li><a href="#">Terms & Condition</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div class="footer__widget mb-30">
                      <div class="footer__widget-title footer__widget-title-2 mb-25">
                          <h5>Customer Service</h5>
                      </div>
                      <div class="footer__widget-content">
                          <div class="footer__links footer__links-2">
                              <ul>
                                  <li><a href="#">Shipping Policy</a></li>
                                  <li><a href="#">Help & Contact Us</a></li>
                                  <li><a href="#">Returns & Refunds</a></li>
                                  <li><a href="#">Online Stores</a></li>
                                  <li><a href="#">Terms & Conditions</a></li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="footer__bottom footer__bottom-2">
      <div class="container">
          <div class="footer__bottom-inner footer__bottom-inner-2">
              <div class="row">
                  <div class="col-xl-6 col-lg-7">
                      <div class="footer__copyright footer__copyright-2">
                          <p>Copyright © <a routerLink="/home/home-style-1">Outstock</a> all rights reserved. Powered by <a routerLink="/home/home-style-1">Theme_pure</a></p>
                      </div>
                  </div>
                  <div class="col-xl-6 col-lg-5">
                      <div class="footer__social footer__social-2 f-right">
                          <ul>
                            <li *ngFor="let item of social_links">
                              <a [href]="item.link" target="_blank"><i [class]="item.icon"></i></a>
                            </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
