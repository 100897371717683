import { Component, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-breadcrumb-one',
  templateUrl: './breadcrumb-one.component.html',
  styleUrls: ['./breadcrumb-one.component.scss']
})
export class BreadcrumbOneComponent {
  @Input() showBg: boolean = false;
  @Input() bg?: string;
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() isLoading:boolean= false;

  public bg_img = '/assets/img/page-title/page-title-1.jpg';

  constructor(private titleService: Title) {

  }

  ngOnInit() {
    if (!!this.title) {
      this.titleService.setTitle(this.title);
    }

    if (this.bg) {
      this.bg_img = this.bg;
    }
  }
}
