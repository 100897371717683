<div class="features__product">
  <ul *ngIf="!!feature_products&&feature_products.length>0">
      <li *ngFor="let item of feature_products" class="mb-20">
          <div class="featires__product-wrapper d-flex">
              <div class="features__product-thumb mr-15">
                  <a [routerLink]="['/shop/shop-details', item.id]">
                    <img [src]="item.img" alt="pro-sm-1" style="height: 110px;width: 86px;">
                  </a>
              </div>
              <div class="features__product-content">
                  <h5><a [routerLink]="['/shop/shop-details', item.id]">{{item.title}}</a></h5>
                  <div class="price">
                      <span>{{item.price?.toFixed(2)| currency:"EUR":"symbol"}}</span>
                      <span *ngIf="item.old_price" class="price-old">{{item.old_price.toFixed(2)|currency:"EUR":"symbol"}}</span>
                  </div>
              </div>
          </div>
      </li>
  </ul>
</div>
