<!-- product banner area start -->
<div class="banner__area-2 pb-30">
  <div class="container-fluid p-0" [ngClass]="['container-fluid', style_2 ? '' : 'p-0']">
      <div class="row g-0">
          <div *ngFor="let item of bannerProducts;let index = index" class="col-xl-6 col-lg-6">
              <div [ngClass]="['banner__item-2 p-relative mb-30',index === 0 ? 'banner-right pr-15' : 'banner-left pl-15']">
                  <div class="banner__thumb fix">
                      <a [routerLink]="['/shop/shop-details', item.id]" class="w-img">
                        <img [src]="item.banner_img" alt="banner">
                      </a>
                  </div>
                  <div [ngClass]="['banner__content-2', style_3 ? 'banner__content-4' : '', index !== 0 && style_3 ? 'banner__content-4-right' : '', 'p-absolute transition-3']">
                      <span>Products {{item.category}}</span>
                      <h4><a [routerLink]="['/shop/shop-details', item.id]">{{item.title}}</a></h4>
                      <p [ngStyle]="{'max-width.px': style_3 ? 250 : 450}">{{ style_3 ? item.dm_desc?.slice(0, 50) : item.dm_desc }}</p>
                      <a [routerLink]="['/shop/shop-details', item.id]" class="os-btn os-btn-2">Acquista /
                        <span>{{item.price!.toFixed(2)|currency:"EUR":"symbol"}}</span>
                      </a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- product banner area end -->
