import { Component, Input } from '@angular/core';
import { IProductDTO } from 'src/app/shared/services/api.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss']
})

export class ProductListItemComponent {
  @Input() product!: IProductDTO;

  constructor(
    public cartService: CartService,
    public utilsService: UtilsService,
  ) {}

  // add to cart
  addToCart(item: IProductDTO) {
    this.cartService.addCartProduct(item);
  }

  // Function to check if an item is in the cart
  isItemInCart(item: IProductDTO): boolean {
    return this.cartService.getCartProducts().some((prd: IProductDTO) => prd.id === item.id);
  }
}
