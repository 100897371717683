<!--hero slider area start -->
<section class="slider__area p-relative">
    <section class="slider__area slider__area-2 p-relative">
        <div class="slider-active swiper-container" #heroSliderContainer>
            <div class="swiper-wrapper">
                <img *ngFor="let item of aws_home_slider" src="{{item.getPreSignedURL}}"
                class="object-fit-fill border rounded swiper-slide "  i18n-alt="{{aws_home_slider.indexOf(item)}}" alt="{{aws_home_slider.indexOf(item)}}">
            </div>
            <div class="tp-slider-dot-2"></div>
        </div>
    </section>
</section>
<!--hero slider area end -->