import { Component, OnDestroy, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { IMobileMenu } from '../../types/menu-d-t';
import { mobile_menus } from '../../data/menu-data';
import { Service } from '../../services/api.service';
import { map, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AuthStore } from '../../store/auth.store';


@Component({
  selector: 'app-offcanvas',
  templateUrl: './offcanvas.component.html',
  styleUrls: ['./offcanvas.component.scss']
})

export class OffcanvasComponent implements OnInit {

  mobile_menus: IMobileMenu[] = mobile_menus;
  mobile_menus_copy: IMobileMenu[] = [];
  activeMenu: string = "";
  protected isLogged: boolean = false;

  constructor(public utilsService: UtilsService, protected router: Router, protected authStore: AuthStore) {

    this.authStore.getUserData().pipe(
      tap(userData => {
        this.isLogged = !!userData;
        this.populateNavItems();


      })
    ).subscribe();
  }

  ngOnInit(): void {

  }

  handleOpenMenu(navTitle: string) {
    if (navTitle === this.activeMenu) {
      this.activeMenu = "";
    } else {
      this.activeMenu = navTitle;
    }
  }

  populateNavItems(): void {

    this.mobile_menus_copy = [];    
    this.mobile_menus_copy = JSON.parse(JSON.stringify( this.mobile_menus));

    this.utilsService.populateMobileNavItems(this.mobile_menus_copy);


    if (this.isLogged) {
      this.mobile_menus_copy.filter(e => e.title === 'Sito')[0].dropdownMenu?.push(
        { link: "/pages/logout", title: "Esci" })
    }
    else {
      this.mobile_menus_copy.filter(e => e.title === 'Sito')[0].dropdownMenu?.push(
        { link: "/pages/login", title: "Accedi" })
    }

  }
  public async navigate(evt: Event, link: string): Promise<void> {
    var result = await this.router.navigate([link]);
    if (result) window.location.reload();
  }

}
