<div class="product__wrapper mb-60">
    <div class="product__thumb">
        <a class="cursor-pointer w-img" data-bs-toggle="modal" [attr.data-bs-target]="'#' + utilsService.modalId"
            (click)="utilsService.handleOpenModal('product-modal-' + product.id, product)">

            <img [src]="product.img??'./../../../assets/img/logo/logo.svg'" alt="product-img">
            <img class="product__thumb-2" *ngIf="(!!product.relatedImages&&product.relatedImages!.length>0)"
                [src]="product.thumb_img?? (!!product.relatedImages?product.relatedImages[0]:'')" alt="product-img">
        </a>
        <div class="product__action transition-3">
                      <a [routerLink]="['/shop/shop-details', product.id]" class="cursor-pointer font-3">
                <i class="fas fa-arrow-alt-circle-right"></i>
            </a>
        </div>
        <div *ngIf="product.isPromo || product.isNew" class="product__sale">
            <!-- <span *ngIf="product.isPromo" class="new">PROMO</span> -->
            <span *ngIf="product.isPromo&& !!product.discountValue; else newprod" class="percent">-{{ product.discountValue }}%</span>
          <ng-template #newprod>

              <span *ngIf="product.isNew" class="new">New!</span>
            </ng-template>
        </div>
    </div>
    <div class="product__content p-relative">
        <div class="product__content-inner">
            <h4><a [routerLink]="['/shop/shop-details', product.id]" [innerHTML]="product.title"></a></h4>
            <div class="product__price transition-3">
                <span [ngClass]="(product.old_price&& product.price!<product.old_price!)?'text-theme':''">{{product.price?.toFixed(2)|currency:"EUR":"symbol"}}</span>
                <span *ngIf="product.old_price&& product.price!<product.old_price!"
                    class="old-price">{{product.old_price.toFixed(2)| currency:"EUR":"symbol"}}</span>
            </div>
        </div>
        <div class="add-cart p-absolute transition-3">
            <a *ngIf="!isItemInCart(product)" (click)="addToCart(product)" class="cursor-pointer">
                + Aggiungi al carrello
            </a>
            <a *ngIf="isItemInCart(product)" routerLink="/shop/cart" class="cursor-pointer" i18n>
                Vai al carrello
            </a>
        </div>
    </div>
</div>