import { Component, EnvironmentInjector, EventEmitter, Input, Output } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { CartService } from '../../services/cart.service';
import { IProductDTO } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';

@Component({
  selector: 'app-product-details-upper',
  templateUrl: './product-details-upper.component.html',
  styleUrls: ['./product-details-upper.component.scss'],
})
export class ProductDetailsUpperComponent {
  @Input() product!: IProductDTO;
  @Input() bottomShow: boolean = true;
  @Input() style_2: boolean = false;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  protected hideClickIcon: boolean = false;

  constructor(
    public productService: ProductService,
    public cartService: CartService,
    protected router: Router,
  ) {

    this.hideClickIcon = this.router.url.includes("/shop/shop-details");
  }

  ngOnInit() {
    if (this.product) {
      this.productService.activeImg = this.product.img;
    }

  }

  onTitleClick(svt: Event): void {
    this.closeModal.emit();
  }
}
