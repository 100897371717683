<section class="shop__area pb-15
">
  <div class="shop__top grey-bg-6 pt-100">
    <div class="container bg-white py-4">
      <app-product-details-upper [product]="product!"></app-product-details-upper>
    </div>
  </div>

  <div class="shop__bottom" *ngIf="!!product?.details?.details_text_2||!!product?.dimension|| !!product?.weight">
    <div class="container bg-white">
      <div class="row">
        <div class="col-xl-12">
          <div class="product__details-tab pt-25">
            <div class="product__details-tab-nav text-center mb-45">
              <nav>
                <div class="nav nav-tabs justify-content-start justify-content-sm-center" id="pro-details"
                  role="tablist">
                  <a class="nav-item nav-link active" id="des-tab" data-bs-toggle="tab" href="#des" role="tab"
                    aria-controls="des" aria-selected="true" i18n
                    *ngIf="!!product?.details?.details_text_2">Descrizione</a>
                  <a class="nav-item nav-link" id="add-tab" data-bs-toggle="tab" href="#add" role="tab"
                    aria-controls="add" aria-selected="false" i18n>Informazioni aggiuntive</a>
                  <!-- <a class="nav-item nav-link" id="review-tab" data-bs-toggle="tab" href="#review" role="tab" aria-controls="review" aria-selected="false">Reviews ({{product?.reviews?.length}})</a> -->
                </div>
              </nav>
            </div>
            <div class="tab-content" id="pro-detailsContent">
              <div class="tab-pane fade show active" id="des" role="tabpanel">
                <div class="product__details-des p-1">
              
                  <p [innerHTML]="product?.details?.details_text_2"></p>
                </div>
              </div>
              <div class="tab-pane fade" id="add" role="tabpanel">
                <div class="row">
                  <div class="vstack row-gap-2">
                    <div class="col-12" *ngIf="!!product?.shippingPrice&&product!.shippingPrice!>0">
                      <div class="hstack">
                        <div class="col bg-body-secondary p-1 rounded">Spedizione con Corriere</div>
                        <div class="col text-center">{{product?.shippingPrice| currency:"EUR"}}</div>
                      </div>
                    </div>
                    <div class="col-12" *ngIf="!!product?.localPrice&&product!.localPrice!>0">
                      <div class="hstack">
                        <div class="col bg-body-secondary p-1 rounded">Trasportatore Locale</div>
                        <div class="col text-center">{{product?.localPrice| currency:"EUR"}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <div class="product__details-add my-1" *ngIf="!!product?.weight">
                    <ul>
                      <li><span i18n>Peso</span></li>
                      <li><span>{{product?.weight}}</span></li>
                    </ul>
                  </div>

                  <div class="d-flex flex-column flex-md-row my-1" *ngIf="!!product?.dimension">                   
                    <div class="col-12">
                        <div class="col-12 bg-body-secondary p-1 rounded">Misure</div>
                        <div class="col-12 text-center">
                          <div class="product__details-des-list mb-20">
                            <div class="col my-1" *ngFor="let list of product?.details?.details_list">
                              <span>{{list}}</span>
                            </div>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>