import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HeaderOneComponent } from './header/header-one/header-one.component';
import { NavManusComponent } from './header/header-com/nav-manus/nav-manus.component';
import { FooterOneComponent } from './footer/footer-one/footer-one.component';
import { BackToTopComponent } from './components/back-to-top/back-to-top.component';
import { HeroSliderTwoComponent } from './components/slider/hero-slider-two/hero-slider-two.component';
import { MiniCartComponent } from './header/header-com/mini-cart/mini-cart.component';
import { ExtraInfoComponent } from './header/header-com/extra-info/extra-info.component';
import { FooterTwoComponent } from './footer/footer-two/footer-two.component';
import { SearchPopupComponent } from './header/header-com/search-popup/search-popup.component';
import { FooterThreeComponent } from './footer/footer-three/footer-three.component';
import { BreadcrumbOneComponent } from './components/breadcrumb/breadcrumb-one/breadcrumb-one.component';
import { PaginationComponent } from './ui/pagination/pagination.component';
import { ProductDetailsUpperComponent } from './components/product-details-upper/product-details-upper.component';

import { ProductModalComponent } from './components/modals/product-modal/product-modal.component';
import { ContactFormComponent } from './components/forms/contact-form/contact-form.component';
import { OffcanvasComponent } from './components/offcanvas/offcanvas.component';
import { AppointmentBannerComponent } from './components/appointment-banner/appointment-banner.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LogoComponent } from './components/logo/logo.component';
import { AppointmentConfirmationModalComponent } from './components/modals/appointment-confirmation-modal/appointment-confirmation-modal.component';
import { AppointmentConfirmationDetailsComponent } from './components/appointment-confirmation-details/appointment-confirmation-details.component';
import { TagComponent } from './components/tag/tag.component';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { DelvieryAddressComponent } from './components/delivery-address/delivery-address.component';
import { TaxIdValidatorDirective } from './components/directives/fiscalcode-taxid.directive';
import { HeroSliderForComponent } from './components/slider/hero-slider-for/hero-slider-four.component';
import { DeliveryCompanyComponent } from './components/delivery/delivery-company/delivery-company.component';

@NgModule({
  declarations: [
    //direttive
    TaxIdValidatorDirective,
    //componenti
    HeaderOneComponent,
    NavManusComponent,
    FooterOneComponent,
    BackToTopComponent,
    HeroSliderTwoComponent,
    MiniCartComponent,
    ExtraInfoComponent,
    FooterTwoComponent,
    SearchPopupComponent,
    FooterThreeComponent,
    BreadcrumbOneComponent,
    PaginationComponent,
    ProductDetailsUpperComponent,
    ProductModalComponent,
    ContactFormComponent,
    OffcanvasComponent,
    AppointmentBannerComponent,
    LoaderComponent,
    LogoComponent,
    AppointmentConfirmationModalComponent,
    AppointmentConfirmationDetailsComponent,
    TagComponent,
    DeliveryComponent,
    PaymentsComponent,
    DelvieryAddressComponent,
    HeroSliderForComponent,
    DeliveryCompanyComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    //direttive
    TaxIdValidatorDirective,
    //componenti
    HeaderOneComponent,
    FooterOneComponent,
    BackToTopComponent,
    HeroSliderTwoComponent,
    HeroSliderForComponent,
    FooterTwoComponent,
    FooterThreeComponent,
    BreadcrumbOneComponent,
    PaginationComponent,
    ProductDetailsUpperComponent,
    ProductModalComponent,
    ContactFormComponent,
    OffcanvasComponent,
    AppointmentBannerComponent,
    LoaderComponent,
    LogoComponent,
    AppointmentConfirmationModalComponent,
    TagComponent,
    DeliveryComponent,
    PaymentsComponent,
    DeliveryCompanyComponent,
    DelvieryAddressComponent,
  ]
})
export class SharedModule { }
