import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IProductDTO, IProductTagServiceModel } from 'src/app/shared/services/api.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-tag-filtering',
  templateUrl: './tag-filtering.component.html',
  styleUrls: ['./tag-filtering.component.scss']
})
export class TagFilteringComponent {
  @Input() products: IProductDTO[] = [];
  @Output() onSelectedTags: EventEmitter<IProductTagServiceModel[]> = new EventEmitter<IProductTagServiceModel[]>();
  protected tags: IProductTagServiceModel[] = [];
  protected selectedTags: IProductTagServiceModel[] = [];

  constructor(protected utilsService: UtilsService) {
  }

  getProductTags() {
    if (this.products.length > 0) {
      this.products.forEach(p => this.tags.push(p.tags! as IProductTagServiceModel))
      //disinct
      this.tags = Array.from(new Set(this.tags));
    }
  }

  handleOnTagSelected(tag: IProductTagServiceModel) {
    if (this.selectedTags.includes(tag)) {
      this.selectedTags.splice(this.selectedTags.indexOf(tag), 0)
      var toRemove = this.selectedTags.filter(i => i === tag)[0]!;
      this.selectedTags.splice(this.selectedTags.indexOf(toRemove), 1);

    } else {
      this.selectedTags.push(tag);
    }
    this.onSelectedTags.emit(this.selectedTags);
  }

  isSelectedtag(tag: IProductTagServiceModel) {
    return this.selectedTags.includes(tag);
  }

}
