<div class="row">
  <div [ngClass]="style_2 ? 'col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12' : 'col-xl-6 col-lg-6'">

    <div *ngIf="!style_2" class="product__modal-box d-flex flex-column justify-content-center">
      <div class="mb-20">
        <div class="product__modal-img product__thumb w-img">
          <img [src]="productService.activeImg" alt="product-img">
          <div *ngIf="product.isNew || product.isPromo" class="product__sale">
            <span *ngIf="product.isNew" class="new">new</span>
            <span *ngIf="!!product.old_price&& product.price!<product.old_price!" class="percent">-{{
              product.discountValue }}%</span>
          </div>
        </div>
      </div>
      <div class="product__modal-nav mr-20">
        <nav>
          <div class="d-flex flex-row gap-2 overflow-x-auto nav-tabs" id="product-details" role="tablist">
            <a *ngFor="let img of product.relatedImages??[]; index as i" class="nav-item nav-link mb-1 cursor-pointer p-1"
              [class.active]="img === productService.activeImg">
              <div class="product__nav-img w-img" (click)="productService.handleImageActive(img)">
                <img [src]="img" alt="product-img">
              </div>
            </a>
          </div>
        </nav>
      </div>
    </div>

    <div *ngIf="style_2" class="product__modal-box">
      <div class="mb-20">
        <div class="product__modal-img w-img">
          <img [src]="productService.activeImg" alt="product-img">
        </div>
      </div>
      <nav>
        <div class="nav nav-tabs" id="product-details" role="tablist">
          <a *ngFor="let img of product.relatedImages??[]" class="nav-item nav-link cursor-pointer me-2 p-1"
            [class.active]="img === productService.activeImg">
            <div class="product__nav-img w-img" (click)="productService.handleImageActive(img)">
              <img [src]="img" alt="product-img">
            </div>
          </a>
        </div>
      </nav>
    </div>

  </div>

  <div [ngClass]="style_2 ? 'col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12' : 'col-xl-6 col-lg-6'">
    <div class="product__modal-content product__modal-content-2">
      <h4 class="mb-0" (click)="onTitleClick($event)"><a [routerLink]="['/shop/shop-details', product.id]">
          {{product.title}} &nbsp; <i class="far fa-hand-pointer" *ngIf="!hideClickIcon"></i></a>
        </h4>
      <div class="d-flex flex-column justify-content-start  mb-25">
        <em>{{product.itemCode}}</em>
        <span  *ngIf="!!product.category">{{product.category}}</span>
        <span  *ngIf="!!product.parentCategory">{{product.parentCategory}}</span>
      </div>
      <div class="product__price-2 mb-10">
        <span [ngClass]="(product.old_price&& product.price!<product.old_price!)?'text-theme':''">{{product.price?.toFixed(2)| currency:"EUR":"symbol"}}</span>
        <span *ngIf="product.old_price&& product.price!<product.old_price!" class="old-price"
          i18n>{{product.old_price.toFixed(2)| currency:"EUR":"symbol"}}</span>
      </div>
      <div class="product__modal-des mb-30" [innerHTML]="product.dm_desc">
      </div>
      <div class="product__modal-form mb-30">
        <form action="#">
          <div class="product__modal-required mb-5" *ngIf="!!product.quantity&&product.quantity>1">
            <span i18n>Campi obbligatori *</span>
          </div>
          <div class="pro-quan-area d-sm-flex align-items-center">
            <div class="product-quantity-title" *ngIf="!!product.quantity&&product.quantity>1">
              <label i18n>Quantità</label>
            </div>
            <div class="product-quantity mr-20 mb-20" *ngIf="!!product.quantity&&product.quantity>1">
              <div class="cart-plus-minus">
                <input type="text" [value]="cartService.orderQuantity" disabled readonly />
                <div (click)="cartService.decrement()" class="dec qtybutton">-</div>
                <div (click)="cartService.increment()" class="inc qtybutton">+</div>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center">

              <div class="pro-cart-btn">
                <a (click)="cartService.addCartProduct(product)" class="add-cart-btn mb-20 cursor-pointer" i18n>
                  Carrello
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="bottomShow && !!product.category">
        <div class="product__tag mb-25">
          <span i18n>Categoria:</span>
          <span><a class="cursor-pointer">{{product.category}}</a></span>
        </div>
      </div>
    </div>
  </div>
</div>