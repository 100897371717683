<form [formGroup]="checkoutForm" *ngIf="!Isloading">
    <div class="d-flex flex-column row-gap-2 mb-10">
        
        <div class="row mb-10">
            <small>** campi obbligatori</small>
        </div>
        <div class="">
            <label>Name **</label>
            <input type="text" class="form-control" formControlName="firstName" required (input)="notifyParent($event)">
            <div *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched)" class="alert alert-danger my-1">
                <div *ngIf="firstName?.errors?.['required']" i18n>Nome destinario obbligatorio</div>
            </div>
        </div>

        <div class="">
            <label>Cognome **</label>
            <input type="text" class="form-control" formControlName="lastName" required (input)="notifyParent($event)">
            <div *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched)" class="alert alert-danger my-1">
                <div *ngIf="lastName?.errors?.['required']" i18n>Nome destinario obbligatorio</div>
            </div>
        </div>

        <div class="">
            <label>Nome dalla società</label>
            <input type="text" class="form-control" formControlName="company" (input)="notifyParent($event)">
        </div>

        <div class="">
            <label>Indirizzo **</label>
            <input type="text" class="form-control" formControlName="address" required (input)="notifyParent($event)">
            <div *ngIf="address?.invalid && (address?.dirty || address?.touched)" class="alert alert-danger my-1">
                <div *ngIf="address?.errors?.['required']" i18n>La via è obbligatoria</div>
            </div>
        </div>

        <div class="">
            <label>Località **</label>
            <input type="text" class="form-control" formControlName="country" required (input)="notifyParent($event)">
            <div *ngIf="country?.invalid && (country?.dirty || country?.touched)" class="alert alert-danger my-1">
                <div *ngIf="country?.errors?.['required']" i18n>Località obbligatoria</div>
            </div>
        </div>
        <div class="">
            <label>CAP **</label>
            <input type="text" class="form-control" formControlName="zipCode" required (input)="notifyParent($event)">
            <div *ngIf="zipCode?.invalid && (zipCode?.dirty || zipCode?.touched)" class="alert alert-danger my-1">
                <div *ngIf="zipCode?.errors?.['required']" i18n>Codice aviamento postale obbligatorio</div>
            </div>
        </div>
        <div class="">
            <label>Città **</label>
            <input type="text" class="form-control" formControlName="city" required (input)="notifyParent($event)">
            <div *ngIf="city?.invalid && (city?.dirty || city?.touched)" class="alert alert-danger my-1">
                <div *ngIf="city?.errors?.['required']" i18n>Città obbligatoria</div>
            </div>
        </div>
        <div class="">
            <label>Provincia **</label>
            <input type="text" class="form-control" formControlName="province" required (input)="notifyParent($event)">
            <div *ngIf="province?.invalid && (province?.dirty || province?.touched)" class="alert alert-danger my-1">
                <div *ngIf="province?.errors?.['required']" i18n>La provincia è obbligatoria</div>
            </div>
        </div>
        <div class="">
            <label>Codice fiscale/Partita IVA **</label>
            <input type="text" class="form-control" formControlName="fiscalcode" (input)="notifyParent($event)">
            <div *ngIf="fiscalcode?.invalid && (fiscalcode?.dirty || fiscalcode?.touched)"
                class="alert alert-danger my-1">
                <div i18n>Codice Fiscale obbligatorio</div>
            </div>
        </div>

        <div class="">
            <label>Recapito Telefonico: **</label>
            <input type="text" class="form-control" formControlName="phone" required (input)="notifyParent($event)">
            <div *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)" class="alert alert-danger my-1">
                <div *ngIf="phone?.errors?.['required']" i18n>Recapito telefonico necessario per il corriere!</div>
                <div *ngIf="phone?.errors" i18n>Numero di telefono non valido, controlla che non ci siano spazi o che il numero inserito abbia 10 numeri</div>

            </div>
        </div>
        <div class="">
            <label>Email **</label>
            <input type="text" class="form-control" formControlName="email" required (input)="notifyParent($event)">
            <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="alert alert-danger my-1">
                <div *ngIf="email?.errors?.['required']" i18n>Devi inserire una email per ricevere gli aggiornamenti
                    sull'ordine!</div>
                    <div *ngIf="email?.errors" i18n>Email non valida</div>

            </div>
        </div>
        <div class="">
            <label>Note per la spedizione</label>
            <textarea rows="3" class="form-control" formControlName="orderNote" (input)="notifyParent($event)"></textarea>
        </div>

    </div>
</form>

<div class="d-flex flex-row justify-content-center" *ngIf="false">
    <button class="btn theme-bg" type="button" (click)="showInvoiceAddress($event)">Spedizione indirizzo diverso</button>
</div>

<form [formGroup]="invoiceForm" *ngIf="hasAddressInovice">
    <div class="d-flex flex-column row-gap-2 mb-10">

        <div class="">
            <label>Name **</label>
            <input type="text" class="form-control" formControlName="firstName_i" required (input)="notifyInvoice($event)">
            <div *ngIf="firstName_i?.invalid && (firstName_i?.dirty || firstName_i?.touched)"
                class="alert alert-danger my-1">
                <div *ngIf="firstName_i?.errors?.['required']" i18n>Nome destinario obbligatorio</div>
            </div>
        </div>

        <div class="">
            <label>Cognome **</label>
            <input type="text" class="form-control" formControlName="lastName_i" required (input)="notifyInvoice($event)">
            <div *ngIf="lastName_i?.invalid && (lastName_i?.dirty || lastName_i?.touched)"
                class="alert alert-danger my-1">
                <div *ngIf="lastName_i?.errors?.['required']" i18n>Nome destinario obbligatorio</div>
            </div>
        </div>

        <div class="">
            <label>Nome dalla società</label>
            <input type="text" class="form-control" formControlName="company_i">
        </div>

        <div class="">
            <label>Indirizzo **</label>
            <input type="text" class="form-control" formControlName="address_i" required (input)="notifyInvoice($event)">
            <div *ngIf="address_i?.invalid && (address_i?.dirty || address_i?.touched)" class="alert alert-danger my-1">
                <div *ngIf="address_i?.errors?.['required']" i18n>La via è obbligatoria</div>
            </div>
        </div>

        <div class="">
            <label>Città **</label>
            <input type="text" class="form-control" formControlName="city_i" required (input)="notifyInvoice($event)">
            <div *ngIf="city_i?.invalid && (city?.dirty || city_i?.touched)" class="alert alert-danger my-1">
                <div *ngIf="city_i?.errors?.['required']" i18n>La città è obbligatoria</div>
            </div>
        </div>

        <div class="">
            <label>CAP **</label>
            <input type="text" class="form-control" formControlName="zipCode_i" required (input)="notifyInvoice($event)">
            <div *ngIf="zipCode_i?.invalid && (zipCode_i?.dirty || zipCode_i?.touched)" class="alert alert-danger my-1">
                <div *ngIf="zipCode_i?.errors?.['required']" i18n>Codice aviamento postale obbligatorio</div>
            </div>
        </div>

        <div class="">
            <label>Codice fiscale/Partita IVA **</label>
            <input type="text" class="form-control" formControlName="fiscalcode_i" required (input)="notifyInvoice($event)">
            <div *ngIf="fiscalcode_i?.invalid &&(fiscalcode_i?.dirty || fiscalcode_i?.touched)"
                class="alert alert-danger my-1">
                <div i18n>Codice Fiscale obbligatorio</div>
            </div>
        </div>

        <div class="">
            <label>Recapito Telefonico **</label>
            <input type="text" class="form-control" formControlName="phone_i" required (input)="notifyInvoice($event)">
            <div *ngIf="phone_i?.invalid && (phone_i?.dirty || phone_i?.touched)" class="alert alert-danger my-1">
                <div *ngIf="phone_i?.errors?.['required']" i18n>Recapito telefonico necessario per il corriere!</div>
                <div *ngIf="phone_i?.errors" i18n>Numero di telefono non valido, controlla che non ci siano spazi</div>
            </div>
        </div>
        <div class="">
            <label>Email **</label>
            <input type="text" class="form-control" formControlName="email_i" required (input)="notifyInvoice($event)">
            <div *ngIf="email_i?.invalid && (email?.dirty || email_i?.touched)" class="alert alert-danger my-1">
                <div *ngIf="email_i?.errors?.['required']" i18n>Devi inserire una email per ricevere gli aggiornamenti
                    sull'ordine!</div>
                    <div *ngIf="email_i?.errors" i18n>Email non valida</div>
            </div>
        </div>
        <div class="">
            <label>Note per la spedizione</label>
            <textarea rows="3" class="form-control" formControlName="orderNote_i" (input)="notifyInvoice($event)"></textarea>
        </div>

    </div>
</form>