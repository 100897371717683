export const environment = {
  name: 'Staging',
  production: false,
  staging: true,
  apiURL: 'https://auth-usatomania.svctech.it',
  EncryptKey: '3c3151a7c65ca6f94712aad6b79e3722',
  EncryptIV: '6?xv?EjDs7Y2=dvF',
  googleAnalytics:''

};
