import { Component, Input } from '@angular/core';
import { IAppointmentServiceModel } from '../../services/api.service';
import html2canvas from 'html2canvas';
import { ArgumentOutOfRangeError, min } from 'rxjs';

@Component({
  selector: 'app-appointment-confirmation-details',
  templateUrl: './appointment-confirmation-details.component.html',
  styleUrls: ['./appointment-confirmation-details.component.scss']
})
export class AppointmentConfirmationDetailsComponent {
  @Input() appointment!: IAppointmentServiceModel;
  @Input() readonly: boolean = false;
}
