import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})

export class LogoComponent {
  @Input() height: string | undefined;
  protected logo_id: string = "logo-component-image";

  constructor() {
    if (!!this.height) {
      var img_el = document.getElementById(this.logo_id);
      if(!!img_el) img_el.style.height= this.height;
    }
  }
}
