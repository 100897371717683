<form id="contact-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
  <div class="row">
      <div class="col-xl-6 col-lg-6">
          <div class="contact__input mb-20">
              <label>Nome <span class="required">*</span></label>
              <input type="text" id="name" formControlName="name">
              <span *ngIf="name?.hasError('required') && (name?.touched || formSubmitted)" class="text-danger" i18n>
                Il nome è obbligatorio
              </span>
          </div>
      </div>
      <div class="col-xl-6 col-lg-6">
          <div class="contact__input mb-20">
              <label>Email <span class="required">*</span></label>
              <input type="email" id="email" formControlName="email">
              <span *ngIf="(email?.hasError('required') && email?.touched) ||(email?.hasError('email') && email?.dirty) || (formSubmitted && !email?.value)" class="text-danger" i18n>
             L'indirizzo email deve essere valido e attivo, altrimenti non potremmo rispondervi!
              </span>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-xl-12">
          <div class="contact__input mb-20">
              <label>Oggetto <span class="required">*</span></label>
              <input type="text" id="subject" formControlName="subject">
              <span *ngIf="subject?.hasError('required') && (subject?.touched || formSubmitted)" class="text-danger" i18n>
               Oggetto di interesse
              </span>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-xl-12">
          <div class="contact__input mb-45">
              <label>Messaggio</label>
              <textarea cols="30" rows="10" id="message" formControlName="message"></textarea>
             <span *ngIf="message?.hasError('required') && (message?.touched || formSubmitted)"  class="text-danger" i18n>
              Inserisci un messaggio, con cosa ti posssiamo aiutare?
             </span>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-xl-12">
          <div class="contact__submit">
              <button type="submit" class="os-btn os-btn-black" i18n>Verifica ed Invia</button>
          </div>
      </div>
  </div>
</form>
