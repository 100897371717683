import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import category_data from 'src/app/shared/data/category-data';
import { CategoryServiceModel, NewUserAccountServiceModel, ProductCategoryServiceModel, Service } from 'src/app/shared/services/api.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ICategoryType } from 'src/app/shared/types/category-d-t';

@Component({
  selector: 'app-search-popup',
  templateUrl: './search-popup.component.html',
  styleUrls: ['./search-popup.component.scss']
})
export class SearchPopupComponent implements OnInit {

  public searchText: string = '';
  protected categories: CategoryServiceModel[] = [];
  protected isLoading: boolean = false;

  constructor(public utilsService: UtilsService, private apiService: Service, private router: Router) {
    this.isLoading = true;
  }

  ngOnInit(): void {
    this.apiService.getAllWebsiteProductCategory(true).pipe(
      tap(result => {
        if (result.success) {
          result.data?.forEach(i => this.categories.push(i));
        }
        this.isLoading = false;
      })
    ).subscribe();
  }

  isCategorySelected(ctg: number): boolean {
    return this.utilsService.isCategorySelected(ctg);
  }

  onKey(event: any) { 
    this.searchText = event.target.value.toString(); 
    var filter= this.utilsService.getSearchFilter();
    filter.searchText= this.searchText;
    this.utilsService.setSearchFilter(filter);
  }

  handleProductType(ctg: number) {
    this.utilsService.updateCategoryList(ctg);
  }

  handleSearchSubmit() {
    
    if (this.router.url.endsWith("/shop")) {
      window.location.reload();
      return;
    }else{
      this.router.navigate(['/shop']);
    }
  }
}
