import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-item-loader',
  templateUrl: './product-item-loader.component.html',
  styleUrls: ['./product-item-loader.component.scss']
})
export class ProductItemLoaderComponent {
@Input() customStyle:string="height:10rem;";
}
