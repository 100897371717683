import { Component,Input } from '@angular/core';
import { IProductDTO } from 'src/app/shared/services/api.service';
import { IProductOld } from 'src/app/shared/types/product-d-t';

@Component({
  selector: 'app-product-details-area',
  templateUrl: './product-details-area.component.html',
  styleUrls: ['./product-details-area.component.scss']
})
export class ProductDetailsAreaComponent {
  @Input() product:IProductDTO | undefined;
}
