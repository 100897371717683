import { R3SelectorScopeMode } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, of, tap } from 'rxjs';
import { OrderServiceModel, Service } from 'src/app/shared/services/api.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})

export class OrderSummaryComponent implements OnInit {

  protected isLoadingPdf: boolean = false;
  protected isLoadingOrder: boolean = false;
  private orderId: string = '';
  protected order: OrderServiceModel | undefined;
  protected pdfSummary: string = '';
  protected orderStatusName:string='';
  protected totalOrderItems:number=0;
  protected totalOrder:number=0;
  /**
   *
   */
  constructor(protected apiStore: Service, protected utilsService: UtilsService, protected route: ActivatedRoute) {
    this.isLoadingOrder = true;
    this.isLoadingPdf = true;
    this.route.paramMap.subscribe(params => {
      this.orderId = params.get('id') ?? '';
    });
  }

  ngOnInit(): void {

    this.apiStore.getOrderPfdSummary(this.orderId).pipe(
      catchError(err => {
        this.utilsService.catchAndShowError(err);
        return of()
      }),
      tap(result => {
        if (result.success) {
          this.pdfSummary = result.data!;
          //set delivery in localstorage
          //set payment in localstorage
        } else {
          //errore
        }
        this.isLoadingPdf = false;
      })).subscribe();


    //get order by code, prende il nome dall'url del controller
    this.apiStore.code(this.orderId).pipe(
      catchError(err => {
        this.utilsService.catchAndShowError(err);
        return of()
      }),
      tap(result => {
        if (result.success) {
          this.order = result.data;
          this.orderStatusName = this.utilsService.getOrderStatusName(this.order?.orderStatusEnum??-1)
          result.data?.previewProductList?.forEach(e=>{
            
            // Calculate the item total without discount
            var qt=1;
            if(!!e.quantity) qt= e.quantity!;

            const itemTotal = e.sellDecimalPrice ?? 0 * qt;
            this.totalOrderItems+= itemTotal;
          });

          this.totalOrder= this.totalOrderItems+ this.order?.deliveryData?.price!??0;
        } else {
          //errore
        }
        this.isLoadingOrder = false;

      })).subscribe();
  }


  createPdfSummary(event: Event) {
    //open new ab
    var pdfWindow = window.open("", "_blank");

    //generate and fill iframe
    var ifrm = document.createElement("iframe");
    ifrm.style.width = "100%";
    ifrm.style.height = "100%";
    // ifrm.setAttribute("src", `data:application/pdf;base64,${pdfBase64}#zoom=100`);
    ifrm.setAttribute("src", `data:application/pdf;base64,${this.pdfSummary}#zoom=100`);

    //append to new page
    pdfWindow!.document.body.appendChild(ifrm);
  }
}
