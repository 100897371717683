<div class="appointment-accordeon-info">

    <div class="accordion" id="objects-collection">
        <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed  fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#ritiro"
                    aria-expanded="false" aria-controls="ritiro">
                    Informazioni sul ritiro
                </button>
            </h2>
            <div id="ritiro" class="accordion-collapse collapse" data-bs-parent="#objects-collection">
                <div class="accordion-body px-3">
                    <p>
                        Ritiriamo 10 pezzi al mese di abbigliamento da indossare (tra donna, uomo e bambini), invece per
                        quanto riguarda borse, scarpe, accessori, bigiotteria non c’è un limite di quantità.
                    </p>
                    <p>
                        <span class="col-12 mb-1">Selezione</span>
                        Ritiriamo abbigliamento donna/uomo per marca, da Zara in su, metre non ritiriamo capi Calliope,
                        Tally, Oviesse, Benetton ed altre marche o prodotti di fattura simile. Si accettano vestiti con
                        tessuti di
                        qualità (esempio: lana, seta, lino, piuma d’oca, cachemire etc) in base alla stagione e/o abiti
                        nuovi, ancora con etichetta.
                    </p>
                    <p>
                        Gli abiti di mezza stagione vanno portati o a
                        Marzo o a Settembre. I jeans lunghi non verranno ritirare nella stagione estiva.
                    </p>
                    <p> L’abbigliamento da bambino non ha bisogno di marca e lo ritiriamo fino agli 8 anni. Così anche
                        scarpe, borse e accessori non necessitano di una marca specifica. </p>

                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed  fw-semibold" type="button" data-bs-toggle="collapse"
                    data-bs-target="#articoli" aria-expanded="false" aria-controls="articoli">
                    Articoli
                </button>
            </h2>
            <div id="articoli" class="accordion-collapse collapse" data-bs-parent="#objects-collection">
                <div class="accordion-body  px-3">

                    <div class="list-group">
                        <div class="list-group-item list-group-item-action" aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1 fw-bold">Attrezzi sportivi</h5>
                            </div>
                            <p class="mb-1">accessori, attrezzi es tapis roulant etc. Abbigliamento tecnico</p>
                        </div>
                        <div class="list-group-item list-group-item-action" aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1 fw-bold">Articoli per animali</h5>
                            </div>
                            <p class="mb-1">cucce, accessori, guinzagli, trasportini etc</p>
                        </div>

                        <div class="list-group-item list-group-item-action" aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1 fw-bold">Oggettistica</h5>
                            </div>
                            <p class="mb-1"> porcellana, ceramica, vetro (tazze, casalinghi etc), giochi</p>
                        </div>

                        <div class="list-group-item list-group-item-action " aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1 fw-bold">Abbigliamento</h5>
                            </div>
                            <p class="mb-1">accessori, bigiotteria, scarpe, borse, abiti uomo/donna/bambini</p>
                        </div>

                        <div class="list-group-item list-group-item-action " aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1 fw-bold">Elettronica</h5>
                            </div>
                            <p class="mb-1">DVD, CD, vinili, videoregistratori, lettori DVD con presa HDMI,
                                amplificatori, vintage HIFI, radio, computer, console tipo Playstation, etc</p>
                        </div>

                        <div class="list-group-item list-group-item-action " aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1 fw-bold">Attrezzatura da lavoro</h5>
                            </div>
                            <p class="mb-1">elettrica o meno, professionale e non, es: trapano, martello, etc</p>
                        </div>

                        <div class="list-group-item list-group-item-action" aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1 fw-bold">Libri</h5>
                            </div>
                            <p class="mb-1">narrativa, gialli, romanzi etc. No libri di scuola a parte dizionari</p>
                        </div>

                        <div class="list-group-item list-group-item-action" aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1 fw-bold">Mobili</h5>
                            </div>
                            <p class="mb-1">mobilio di ogni tipo, previa visione foto che ci serve per capire di che
                                stile e materiale si tratta. Alcuni mobili hanno perso il valore che avevano prima,
                                commerciale più che di fattura.</p>
                        </div>

                        <div class="list-group-item list-group-item-action" aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1 fw-bold">Elettrodomestici piccoli e grandi</h5>
                            </div>
                            <p class="mb-1">dal mixer alla lavastoviglie</p>
                        </div>

                        <div class="list-group-item list-group-item-action" aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1 fw-bold">Collezionismo</h5>
                            </div>
                            <p class="mb-1">francobolli, monete, banconote, orologi, orologi da taschino, pipe, penne da
                                collezione, antichità etc..</p>
                        </div>

                        <div class="list-group-item list-group-item-action" aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1 fw-bold">Oggetti e varie stagionali</h5>
                            </div>
                            <p class="mb-1">ritiriamo accessori e attrezzatura sciistica a Novembre, mute e articoli da
                                spiaggia ad Aprile e Maggio. Zaini scuola e cancelleria prettamente scolastica nel mese
                                di Agosto</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header">
                <button class="accordion-button collapsed  fw-semibold" type="button" data-bs-toggle="collapse"
                    data-bs-target="#articoli-speciali" aria-expanded="false" aria-controls="articoli-speciali">
                    Articoli che rientrano solo in determinati mesi
                </button>
            </h2>
            <div id="articoli-speciali" class="accordion-collapse collapse" data-bs-parent="#objects-collection">
                <div class="accordion-body px-3">
                    <div class="list-group">
                        <div class="list-group-item list-group-item-action" aria-current="true">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">ARTICOLI NATALIZI E PELLICCE</h5>
                            </div>
                            <p class="mb-1  fw-bold">SOLO NEL MESE DI OTTOBRE</p>
                        </div>

                        <div class="list-group-item list-group-item-action">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">ABBIGLIAMENTO E ATTREZZATURA SCIISTICA</h5>
                            </div>
                            <p class="mb-1 fw-bold">MESE DI NOVEMBRE</p>
                        </div>

                        <div class="list-group-item list-group-item-action">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">ARTICOLI PASQUALI</h5>
                            </div>
                            <p class="mb-1 fw-bold">NEL MESE DI MARZO, SE PASQUA CADE AD APRILE, O NEL MESE DI FEBBRAIO SE CADE
                                A MARZO</p>
                        </div>

                        <div class="list-group-item list-group-item-action">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1 fw-bold">ARTICOLI DI CARNEVALE</h5>
                            </div>
                            <p class="mb-1 fw-bold">A GENNAIO</p>
                        </div>

                        <div class="list-group-item list-group-item-action">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">ARTICOLI DI HALLOWEEN</h5>
                            </div>
                            <p class="mb-1 fw-bold">A SETTEMBRE</p>
                        </div>

                        <div class="list-group-item list-group-item-action">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">ARTICOLI DA SPIAGGIA</h5>
                            </div>
                            <p class="mb-1 fw-bold">APRILE E MAGGIO</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>