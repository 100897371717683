<div class="basic-pagination">
  <ul *ngIf="paginate.pages && paginate.pages.length">
    <li [ngClass]="{ disabled: paginate.currentPage === 1 }">
      <a (click)="pageSet(paginate.currentPage - 1)" class="cursor-pointer">
        <i class="fal fa-angle-left"></i>
      </a>
    </li>

    <li *ngFor="let page of paginate.pages" [ngClass]="{ active: paginate.currentPage == page }">
      <a
        class="cursor-pointer"
        (click)="pageSet(page)"
      >
        {{ page }}
      </a>
    </li>

    <li [ngClass]="{ disabled: paginate.currentPage == paginate.totalPages }">
      <a class="cursor-pointer" (click)="pageSet(paginate.currentPage + 1)">
        <i class="fal fa-angle-right"></i>
      </a>
    </li>
  </ul>
</div>
