import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IPreviewQueryModel, IProductDTO, IProductDTOPaginatedResult, IProductDTOPaginatedResultServiceResponse, PreviewQueryModel, ProductDTOListServiceResponse, RelatedProductFilter, Service } from './api.service';
import { select } from '@ngneat/elf';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public filter_offcanvas: boolean = false;
  public pageSize: number = 9;

  /**
   *
   */
  constructor(protected apiService: Service) {

  }

  // Get Products
  public  products$( filter: IPreviewQueryModel): Observable<IProductDTO[]> {
    var result: IProductDTO[] = [];

    return this.apiService.getAllPaginatedProducts(filter as PreviewQueryModel).pipe(
      select(d => {
        if (d.success) {
          if(!!d.data){

            d.data!.items!.forEach(i => {
              if (!i.img) {
                i.img="./../../../assets/img/logo/logo.svg";
              }
              result.push(i);
            });
          }
        } else {
          console.log(d.message)
        }
        return result
      })
    );
  }

  public  newProducts$( filter: IPreviewQueryModel): Observable<IProductDTO[]> {
    var result: IProductDTO[] = [];

    return this.apiService.getNewAllPaginatedProducts(filter as PreviewQueryModel).pipe(
      select(d => {
        if (d.success) {
          if(!!d.data){

            d.data!.items!.forEach(i => {
              if (!i.img) {
                i.img="./../../../assets/img/logo/logo.svg";
              }
              result.push(i);
            });
          }
        } else {
          console.log(d.message)
        }
        return result
      })
    );
  }

  //GetPaginated
  public  paginatedProducts$( filter: IPreviewQueryModel): Observable<IProductDTOPaginatedResult|undefined> {

    return this.apiService.getAllPaginatedProducts(filter as PreviewQueryModel).pipe(
      select(d => {
        if (d.success) {
          if(!!d.data){

            d.data!.items!.forEach(i => {
              if (!i.img) {
                i.img="./../../../assets/img/logo/logo.svg";
              }
            });
            return d.data
          }
        } else {
          console.log(d.message)
        }
        return undefined;
      })
    );
  }

  activeImg: string | undefined;
  defaultimg: string | undefined;

  handleImageActive(img: string) {
    if( this.activeImg === img){
      this.activeImg= this.defaultimg?.toString();
    } else{
      this.activeImg = img;
    }
  }

  // Get Products By id
  public getProductById(id: string): Observable<IProductDTO | undefined> {
    var filter: IPreviewQueryModel = { productId:id, includeReleted:true };
    
    return this.products$(filter).pipe(map(items => {
      const product = items.find(p => p.id === id);
      if (product) {
        this.defaultimg=(product.img ?? "./../../../assets/img/logo/logo.svg")
      }
      return product;
    }));
  }
  // Get related Products
  public getRelatedProducts$(relatedFilter: RelatedProductFilter): Observable<IProductDTO[]> {
    var result: IProductDTO[] = [];
  
    return this.apiService.getReletedProducts(relatedFilter as RelatedProductFilter).pipe(
      select(d => {
        if (d.success) {
          d.data!.forEach(i => {
            if (!i.img) {
             i.img="./../../../assets/img/logo/logo.svg";
            }
            result.push(i);
          });
        } else {
          console.log(d.message)
        }
        return result
      })
    );
  }
  // Get max price
  public get maxPrice(): number {
    return 999;
  }
  // shop filterSelect
  public filterSelect = [
    { value: 'asc', text: 'Default Sorting' },
    { value: 'low', text: 'Low to Hight' },
    { value: 'high', text: 'High to Low' },
    { value: 'on-sale', text: 'On Sale' },
  ];

  // Get Product Filter
  public filterProducts(): Observable<IProductDTO[]> {
    var filter: IPreviewQueryModel = { page: 1, takeItems: 20, 
     };
    return this.products$(filter).pipe(map(product => {
      return product;
    }));
  }


  // Sorting Filter
  public sortProducts(products: IProductDTO[], payload: string): any {

    if (payload === 'asc') {
      return products.sort((a, b) => {
        if (a.id! < b.id!) {
          return -1;
        } else if (a.id! > b.id!) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'sale') {
      return products.filter((p) => p.discountValue! > 0)
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.price! < b.price!) {
          return -1;
        } else if (a.price! > b.price!) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.price! > b.price!) {
          return -1;
        } else if (a.price! < b.price!) {
          return 1;
        }
        return 0;
      })
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 9) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
}
