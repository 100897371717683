<div class="features__product-wrapper d-flex mb-20">
  <div class="features__product-thumb mr-15">
      <a [routerLink]="['/shop/shop-details', product.id]">
        <img [src]="product.img" alt="pro-sm-1" width="86" height="110">
      </a>
  </div>
  <div class="features__product-content">
      <h5>
        <a [routerLink]="['/shop/shop-details', product.id]" [innerHTML]="product.title"></a>
      </h5>
      <div class="price">
          <span>{{product.price!.toFixed(2)|currency:"EUR":"symbol"}}</span>
          <span *ngIf="product.old_price" class="price-old">{{product.old_price!.toFixed(2)|currency:"EUR":"symbol"}}</span>
          <div class="add-cart p-absolute transition-3">
            <a *ngIf="!isItemInCart(product)" (click)="addToCart(product)" class="cursor-pointer">
              + Aggiungi
            </a>
            <a *ngIf="isItemInCart(product)" routerLink="/shop/cart" class="cursor-pointer">
              Vai al carrello
            </a>
          </div>
      </div>
  </div>
</div>
