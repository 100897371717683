import { ApplicationInitStatus, Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { ProductService } from './product.service';
import { AppointmentServiceModel, CategoryServiceModel, IAppointmentServiceModel, IProductCategoryServiceModel, IProductDTO, IStringServiceResponse, PreviewQueryModel, Service, WebCateogryServiceModel } from './api.service';
import { IMenuType, IMobileMenu } from '../types/menu-d-t';

const _search_filter = "search_filter";

@Injectable({
  providedIn: 'root',
})
export class UtilsService {


  public videoUrl: string = 'https://www.youtube.com/embed/EW4ZYb3mCZk';
  public isVideoOpen: boolean = false;
  public isSearchOpen: boolean = false;
  public isProductModalOpen: boolean = false;
  public isAppointmentModalOpen: boolean = false;
  public openMobileMenus: boolean = false;
  public iframeElement: HTMLIFrameElement | null = null;
  // product modal
  public modalId: string = 'product-modal-641e887d05f9ee1717e1348a';
  public modalAppointmentConfirmationId: string = 'product-modal-appointment-confirmation';
  public product: IProductDTO = {};
  public selectedAppointment: IAppointmentServiceModel = {};
  public categories: IProductCategoryServiceModel[] = [];

  public temporatyFilter: PreviewQueryModel;

  constructor(protected apiService: Service,
    private productService: ProductService,
    private router: Router
  ) {

    this.temporatyFilter = this.getSearchFilter();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isSearchOpen = false;
        this.isProductModalOpen = false;
        this.isAppointmentModalOpen = false;
        this.openMobileMenus = false;
        this.removeBackdropAndProductModal()
      }
    });
  }

  /**Consente di trasformare un array in un dizionario */
  arrayToDictionary<T>(array: T[], chunk: number = 10): { [key: string]: T[] } {
    const dictionary: { [key: string]: T[] } = {};
    const chunkSize = chunk;

    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      dictionary[`item_${i / chunkSize}`] = chunk;
    }
    return dictionary;
  }


  populateNavItems(menu_data: IMenuType[]): void {
    //categorie
    menu_data.filter(e => e.title === 'Altro')[0].dropdownItems = [];

    this.apiService.getAllWebsiteProductCategory(true).pipe(
      map(result => {
        if (result.success) {
          var m = menu_data.filter(e => e.title === 'I Nostri Prodotti')[0];
          if (!!m) {
            m.dropdownItems = [];
            //aggiungo i dati alle navigazioni base
            result.data!.sort((a, b) => this.customSort(a.name!, b.name!)).forEach(i => menu_data.filter(e => e.title === 'I Nostri Prodotti')[0].dropdownItems?.push({
              title: i.name!,
              link: `/shop/${i.id!}`
            }));

            localStorage.setItem("wctg", JSON.stringify(result.data))
          }

        } else {
          console.log(`retriving category error ${result.message}`);
        }
      }
      )).subscribe();

    //pagine statiche

    this.apiService.getAllStaticPages(true).pipe(
      map(result => {
        if (result.success) {
          menu_data.filter(e => e.title === 'Altro')[0].dropdownItems = [];

          //aggiungo i dati alle navigazioni base
          //senza vendi con noi
          result.data!.filter(e => e.id !== '8ff2084e-e477-4426-99e0-b85455e9e4d1').forEach(i => menu_data.filter(e =>
            e.title === 'Altro')[0].dropdownItems?.push({
              title: i.title!,
              link: `/pages/static/${i.id!}`
            })
          );
          menu_data.filter(e => e.title === 'Altro')[0].dropdownItems?.push({ link: "/pages/contact", title: "Contattaci" })
        } else {
          console.log(`retriving static pages error ${result.message}`);
        }
      }
      )).subscribe();
  }

  populateMobileNavItems(menu_data: IMobileMenu[]): void {
    //categorie

    menu_data.filter(e => e.title === 'SHOP')[0].dropdownMenu = [];
    menu_data.filter(e => e.title === 'SHOP')[0].dropdownMenu?.push({
      title: "Prodotti",
      link: `/shop`
    });

    menu_data.filter(e => e.title === 'Altro')[0].dropdownMenu = [];
    this.apiService.getAllStaticPages(true).pipe(
      map(result => {
        if (result.success) {
          //aggiungo i dati alle navigazioni base
          result.data!.filter(e => e.id !== '8ff2084e-e477-4426-99e0-b85455e9e4d1').forEach(i => menu_data.filter(e => e.title === 'Altro')[0].dropdownMenu?.push({
            title: i.title!,
            link: `/pages/static/${i.id!}`
          }));
        } else {
          console.log(`retriving static pages error ${result.message}`);
        }
        menu_data.filter(e => e.title === 'Altro')[0].dropdownMenu?.push({ link: "/pages/contact", title: "Contattaci" })

      }
      )).subscribe();
  }

  // open mobile sidebar
  handleOpenMobileMenu() {
    this.openMobileMenus = !this.openMobileMenus;
  };

  // modal video play
  playVideo(videoId: string) {
    const videoOverlay = document.querySelector('#video-overlay');
    this.videoUrl = `https://www.youtube.com/embed/${videoId}`;
    if (!this.iframeElement) {
      this.iframeElement = document.createElement('iframe');
      this.iframeElement.setAttribute('src', this.videoUrl);
      this.iframeElement.style.width = '60%';
      this.iframeElement.style.height = '80%';
    }

    this.isVideoOpen = true;
    videoOverlay?.classList.add('open');
    videoOverlay?.appendChild(this.iframeElement);
  }

  // close modal video
  closeVideo() {
    const videoOverlay = document.querySelector('#video-overlay.open');

    if (this.iframeElement) {
      this.iframeElement.remove();
      this.iframeElement = null;
    }
    this.isVideoOpen = false;
    videoOverlay?.classList.remove('open');
  }

  handleSearchOpen() {
    this.isSearchOpen = !this.isSearchOpen;
  }

  // handle Open Modal
  handleOpenModal(id: string, item: IProductDTO) {
    this.isProductModalOpen = true;
    this.modalId = id;
    this.product = item;
    this.productService.handleImageActive(item.img ?? '/src/assets/img/logo/logo.svg');
  }

  handleOpenAppointmentConfirmationModal(appointment: AppointmentServiceModel) {
    this.selectedAppointment = appointment;
    this.isAppointmentModalOpen = true;

    const btnElement = document.createElement("button");
    btnElement.setAttribute("data-bs-toggle", "modal");
    btnElement.setAttribute("data-bs-target", '#' + this.modalAppointmentConfirmationId);
    document.body.append(btnElement);
    btnElement.click();
    btnElement.remove();
  }

  convertToURL(value: string): string {
    // Replace spaces and special characters with hyphens
    const converted_value = value
      .toLowerCase()
      .replace(/&/g, 'and') // Replace '&' with 'and'
      .replace(/[^a-z0-9-]+/g, '-') // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens

    return converted_value;
  }

  removeBackdropAndProductModal() {
    const modalBackdrop = document.querySelector('.modal-backdrop');
    const product_modal = document.querySelector('.tp-product-modal.show') as HTMLElement;
    if (modalBackdrop) {
      modalBackdrop.remove();
      document.body.classList.remove('modal-open');
      document.body.removeAttribute('style');
    }
    if (product_modal) {
      product_modal.style.display = 'none';
    }
  }


  /**Get from localstorage search filter or new */
  getSearchFilter(): PreviewQueryModel {
    var filter = localStorage.getItem(_search_filter);

    if (!!filter) {
      this.temporatyFilter = JSON.parse(filter) as PreviewQueryModel;
      return this.temporatyFilter;
    } else {
      return {} as PreviewQueryModel;
    }
  }

  /** Set search temporaryFilter */
  setSearchFilter(filter: PreviewQueryModel) {
    this.temporatyFilter = filter;
    this.saveSearchFilter();
  }

  /** Set search filter in localcache */
  saveSearchFilter(): PreviewQueryModel {
    localStorage.setItem(_search_filter, JSON.stringify(this.temporatyFilter))
    return this.temporatyFilter;
  }

  /** Set reset filter in localcache and temporaryFilter*/
  resetSearchFilter() {
    this.temporatyFilter = {} as PreviewQueryModel;
    localStorage.setItem(_search_filter, JSON.stringify(this.temporatyFilter))

  }

  updateCategoryList(ctgid: number) {
    var categories = (JSON.parse(localStorage.getItem("wctg")!)) as CategoryServiceModel[];
    var ctg = categories.filter(e => e.id! === ctgid)[0];
    var ctgIdCategories = ctg.categoryCodeToJoin?.map(c => c.id) ?? [];

    var filter = this.temporatyFilter;
    if (!filter.categoryListIds) filter.categoryListIds = [];
    var ctgAlreadAdded = ctgIdCategories!.every(e => filter.categoryListIds!.includes(e!));
    if (ctgAlreadAdded) {

      var indices = filter.categoryListIds?.filter(e => ctgIdCategories.includes(e)).map(e => filter.categoryListIds?.indexOf(e));

      filter.categoryListIds = filter.categoryListIds.filter((_, index) => !indices.includes(index));
    }
    else {
      ctgIdCategories.forEach(e => {
        filter.categoryListIds!.push(e!);
      });
    }

    localStorage.setItem(_search_filter, JSON.stringify(filter))
  }

  isCategorySelected(ctgId: number): boolean {
    var filter = this.temporatyFilter;

    var categories = (JSON.parse(localStorage.getItem("wctg")!)) as CategoryServiceModel[];
    var ctg = categories.filter(e => e.id! === ctgId)[0];
    var ctgIdCategories = ctg.categoryCodeToJoin?.map(c => c.id) ?? [];
    if (!!ctgIdCategories && !!filter.categoryListIds) {

      return ctgIdCategories!.every(e => filter.categoryListIds!.includes(e!)) ?? false;
    } else {
      return false;
    }
  }

  catchAndShowError(e: any): string {

    //generic error
    var status = e.status;
    var genericMessage = e.message;

    console.error(`${status} - ${genericMessage} ${e.response}`);
    return `${JSON.parse(e.response)}` ?? "Errore generico.";
  }

  customSort(a: string, b: string) {
    const nameA = a.toUpperCase(); // Ignora maiuscole e minuscole
    const nameB = b.toUpperCase(); // Ignora maiuscole e minuscole

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // I nomi sono uguali
    return 0;
  }

  getOrderStatusName(orderStatus: number) {
    var result = '';
    switch (orderStatus) {
      case 0:
        result += "In attesa di pagamento";
        break;
      case 1:
        result += "In attesa di elaborazione";
        break;
      case 2:
        result += "In preparazione";
        break;
      case 3:
        result += "In transito";
        break;
      case 4:
        result += "Consegnato";
        break;
      case 5:
        result += "Annullato";
        break;
      default:
        result += "Sconosciuto";
    }
    return result;
  }
}
