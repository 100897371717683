<div class="product__wrapper mb-40">
  <div class="row">
    <div class="col-xl-4 col-lg-4">
      <div class="product__thumb">
        <a [routerLink]="['/shop/shop-details', product.id]" class="w-img">
          <img [src]="product.img" [attr.alt]="(product.itemCode+'_main')" />
          <img class="product__thumb-2" [src]="product.relatedImages[0]" [attr.alt]="(product.itemCode+'_related')"
            *ngIf="!!product.relatedImages" />
        </a>
        <div *ngIf="product.isPromo || product.isNew" class="product__sale">
          <span *ngIf="product.isNew" class="new" i18n>NEW</span>
          <span *ngIf="product.isPromo" class="percent">-{{ product.discountValue }}%</span>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-8">
      <div class="product__content p-relative">
        <div class="product__content-inner list">
          <h4><a [routerLink]="['/shop/shop-details', product.id]">{{product.title}}</a></h4>
          <div class="product__price-2 mb-10">
            <span>{{product.price?.toFixed(2)|currency:"EUR":"symbol"}}</span>
            <span *ngIf="product.old_price"
              class="old-price">{{product.old_price.toFixed(2)|currency:"EUR":"symbol"}}</span>
          </div>
          <p>{{product.dm_desc}}</p>
          <div class="product__list mb-30">
            <ul>
              <li *ngFor="let list of product.details?.details_list">
                <span>{{list}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="add-cart-list d-sm-flex align-items-center">
          <a *ngIf="!isItemInCart(product)" (click)="addToCart(product)" class="add-cart-btn mr-10 cursor-pointer" i18n>
            <i class="fal fa-plus"></i>
            Aggiungi al carrello
          </a>
          <div class="d-flex flex-row align-items-center gap-2">

            <a *ngIf="isItemInCart(product)" routerLink="/shop/cart" class="add-cart-btn cursor-pointer" i18n>
              Vai al carrello
            </a>
            <div class="transition-3 px-2">
              <!-- Button trigger modal -->
              <a class="cursor-pointer" data-bs-toggle="modal" [attr.data-bs-target]="'#' + utilsService.modalId"
                (click)="utilsService.handleOpenModal('product-modal-' + product.id, product)">
                <i class="fal fa-search"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- shop modal start -->
      </div>
    </div>
  </div>
</div>