<nav>
  <ul>
    <li *ngFor="let item of menu_data; let index = index" [ngClass]="getMenuClasses(item)">
      <a  (click)="navigate($event, item.link)">{{ item.title }}</a>

      <ul *ngIf="item.hasDropdown && !item.megamenu" class="submenu transition-3">
        <li *ngFor="let menu of item.dropdownItems; let index = index">
          <a   (click)="navigate($event, menu.link)" i18n>{{ menu.title }}</a>
        </li>
      </ul>

      <ul *ngIf="item.hasDropdown && item.megamenu" class="submenu transition-3">
        <li *ngFor="let m_mnu of item.dropdownItems; let index = index" class="has-dropdown">
          <a [routerLink]="m_mnu.link" i18n>{{ m_mnu.title }}</a>
          <ul id="categories">
            <li *ngFor="let m of m_mnu.dropdownMenu; let index = index" >
              <a (click)="navigate($event, m.link)" i18n>{{ m.title }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</nav>