import { Component } from '@angular/core';
import { IPreviewQueryModel, IProductDTO } from 'src/app/shared/services/api.service';
import { ProductService } from 'src/app/shared/services/product.service';


@Component({
  selector: 'app-shop-details',
  templateUrl: './shop-details.component.html',
  styleUrls: ['./shop-details.component.scss']
})
export class ShopDetailsComponent {

  public product:IProductDTO | undefined;

  constructor(private productService: ProductService) {
    
  }

}
