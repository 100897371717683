<header [ngClass]="sticky?'pb-200':''">
  <div id="header-sticky"
    [ngClass]="['header__area', transparent ? 'header__transparent' : '', header_big ? 'box-35' : !white_bg ? 'grey-bg' : '', sticky ? 'sticky' : '']"
    id="header-sticky">
    <div [ngClass]="header_big ? 'container-fluid' : 'container'">

      <div class="d-flex flex-column flex-md-row align-items-center">

        <div class="d-flex flex-nowrap align-items-center justify-content-between gap-2" id="logo-c-menu">
            <div class="d-flex flex-column">
              <div class="logo p-2 gap-3">
                <a routerLink="/">
                  <img src="/assets/img/logo/logo.svg" alt="logo" id="logo__header">
                </a>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="mobile-menu-btn d-block d-sm-none f-right">
                <a (click)="utilsService.handleOpenMobileMenu()" class="mobile-menu-toggle cursor-pointer">
                  <i class="fas fa-bars fa-2x"></i>
                </a>
              </div>
            </div>
        </div>

        <div
          [ngClass]="header_big ? 'col-xl-7 col-lg-6 col-md-2 col-sm-1 col-2' : 'col-xl-9 col-lg-9 col-md-8 col-sm-8'">

          <div class="header__right p-relative"
            [ngClass]="{'d-flex justify-content-between align-items-center': !header_big}">
            <div
              [ngClass]="{'main-menu': true, 'main-menu-2': header_big, 'text-center': header_big, 'd-none d-lg-block': true}">
              <!-- nav menu start -->
              <app-nav-manus></app-nav-manus>
              <!-- nav menu end -->
            </div>


            <div *ngIf="!header_big" class="header__action">
              <ul>
                <li>
                  <a (click)="utilsService.handleSearchOpen()" class="search-toggle cursor-pointer">
                    <i class="ion-ios-search-strong"></i> Ricerca
                  </a>
                </li>
                <li>
                  <a class="cart cursor-pointer">
                    <i class="ion-bag"></i> Carrello <span>({{cartService.totalPriceQuantity().quantity}})</span>
                  </a>
                  <!-- mini cart start -->
                  <app-mini-cart></app-mini-cart>
                  <!-- mini cart end -->
                </li>
                <li> <a class="cursor-pointer"><i class="far fa-user"></i></a>
                  <!-- extra info start -->
                  <app-extra-info></app-extra-info>
                  <!-- extra info end -->
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div *ngIf="header_big" class="d-flex flex-row align-items-center gap-3 justify-content-between w-100">
          <div class="header__action header__action-2 f-right">
            <ul>
              <li>
                <a (click)="utilsService.handleSearchOpen()" class="search-toggle cursor-pointer" i18n>
                  <i class="ion-ios-search-strong"></i> Ricerca
                </a>
              </li>
              <li>
                <a class="cart cursor-pointer"><i class="ion-bag" i18n></i> Carrello
                  <span>({{cartService.totalPriceQuantity().quantity}})</span>
                </a>
                <!-- mini cart start -->
                <app-mini-cart></app-mini-cart>
                <!-- mini cart end -->
              </li>
              <li> <a class="cursor-pointer">

                  <span *ngIf="(authStore.IsLogged$ | async )" class="">Profilo</span>
                  <span *ngIf="!(authStore.IsLogged$ | async )" class="">Accedi</span>
                </a>
                <!-- extra info start -->
                <app-extra-info></app-extra-info>
                <!-- extra info end -->
              </li>
            </ul>
          </div>

          <div class="mobile-menu-btn d-none d-sm-block d-lg-none ">
            <a (click)="utilsService.handleOpenMobileMenu()" class="mobile-menu-toggle cursor-pointer">
              <i class="fas fa-bars fa-2x"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- search popup start -->
<app-search-popup></app-search-popup>
<!-- search popup end -->

<!-- mobile offcanvas start -->
<app-offcanvas></app-offcanvas>
<!-- mobile offcanvas end -->