import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShopComponent } from './pages/shop/shop.component';
import { ShopDetailsComponent } from './pages/shop-details/shop-details.component';
import { DynamicShopDetailsComponent } from './pages/dynamic-shop-details/dynamic-shop-details.component';
import { CartComponent } from './pages/cart/cart.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';



const routes: Routes = [
  {
    path:'',
    component:ShopComponent,
    title:'Shop Page'
  },
  {
    path:'shop-details',
    component:ShopDetailsComponent,
    title:'Shop Details Page'
  },
  {
    path:'shop-details/:id',
    component:DynamicShopDetailsComponent,
    title:'Shop Details Page'
  },
  {
    path:'cart',
    component:CartComponent,
    title:'Shop Cart Page'
  },
  {
    path:'orders-summary/:id',
    component:OrderSummaryComponent,
    title:'Dettaglio Ordine'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopRoutingModule { }
