<section class="shop__area pt-20 pb-100 px-4 bg-white">
  <div class="container-fluid ">
    <div class="d-flex flex-lg-row flex-column gap-1">

      <div class="w-100" id="products">


        <div class="row row-gap-1 border bg-body-secondary rounded shadow ">
          <div class="row align-items-center justify-content-between row-gap-2">
            <div class="col-12">
              <!-- category filter start -->
              <app-category-filter (onSelectCategory)="onCategoryUpdate($event)"></app-category-filter>
              <!-- category filter end -->
            </div>
            <div class="col-md-6 col-12" id="search-bar">
              <div class="header__search-input p-relative w-100">
                <input type="text" i18n-placeholder="Ricerca testuale..." placeholder="Ricerca testuale..."
                  [(ngModel)]="searchText" (keyup)="onKey($event)" name="searchText" class="form-control w-100" />
                <button type="submit"><i class="far fa-search"></i></button>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="d-flex flex-column flex-md-row gap-2 align-items-center justify-content-center">
                <div class="row align-items-center justify-content-between justify-content-sm-end" id="select-filters">
                  <div class="col-12 col-md-6">
                    <div class="sort-wrapper mr-30  p-relative my-3">
                      <select [(ngModel)]="sortBy" class="form-select" (ngModelChange)="onSortingChange($event)">
                        <option value="asc">A-Z</option>
                        <option value="sale">In Promozione</option>
                        <option value="high"> Dal meno costoso</option>
                        <option value="low">Dal più costoso</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">

                    <div class="sort-wrapper mr-30  p-relative my-3" *ngIf="!!pages&& !isLoading">
                      <select [(ngModel)]="actualPage" class="form-select" (ngModelChange)="sortByPage($event)">
                        <option *ngFor="let item of pages" [value]="item">Pagina {{item}}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="btn-group btn-md gap-2">
                  <button (click)="handleSubmitFilter($event)" class="os-btn os-btn" i18n>
                  Applica
                </button>
                <button (click)="handleResetFilter($event)" class="os-btn " data-bs-toggle="collapse"
                  data-bs-target="#filters" i18n *ngIf="isFilteredSearch()">
                  Reset
                </button>
                </div>
              </div>
            
            </div>
          </div>

        </div>

        <div class="shop__content-area position-relative">


          <!-- if no item in product  -->
          <div *ngIf="!(isLoading||isLoadingMore)&&products.length === 0" class="cartmini__empty text-center mt-40">
            <div class="w-100 container py-2">

              <img src="/assets/img/shop/empty-cart.png" alt="empty-cart-img" />
              <h4 i18n>Non hai trovato quello che cercavi?</h4>
              <p i18n>Prova a cambiare i filtri della ricerca.</p>
              <a class="os-btn os-btn-3" (click)="handleResetFilter($event)" i18n>Reset filtro</a>
            </div>
          </div>

          <div class="tab-content mt-4" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-grid" role="tabpanel" aria-labelledby="pills-grid-tab">
              <div *ngIf="!isLoading; else loader">
                <div class="row px-3">
                  <div class="col" *ngFor="let item of productData">
                    <app-product-item [product]="item"></app-product-item>
                  </div>
                </div>
              </div>
              <ng-template #loader>
                <div class="row">
                  <div *ngFor="let item of [1,2,3,4,5,6,7,8]" class="col px-3">
                    <app-product-item-loader />
                  </div>
                </div>
              </ng-template>
            </div>

            <div class="tab-pane fade" id="pills-list" role="tabpanel" aria-labelledby="pills-list-tab">
              <div *ngIf="!isLoading; else loader1">
                <ng-container *ngFor="let item of productData">
                  <app-product-list-item [product]="item"></app-product-list-item>
                </ng-container>
              </div>

              <ng-template #loader1>
                <div class="row">
                  <div *ngFor="let item of [1,2,3,4,5,6,7,8]" class="col px-3">
                    <app-product-item-loader />
                  </div>
                </div>
              </ng-template>
            </div>

            <div class="d-flex flex-row justify-content-center"
              *ngIf="(!(isLoading||isLoadingMore)&&productData.length>0)">
              <div class="btn-group gap-2" role="group" aria-label="pagination group buttons">
                <button type="button" class="btn btn-primary" *ngIf="!previous_disabled"
                  (click)="previousPage($event)">Pagina precedente</button>
                <button type="button" class="btn btn-primary disabled" *ngIf="previous_disabled">Pagina
                  precedente</button>
                <button type="button" class="btn btn-primary" *ngIf="!next_disabled" (click)="nextPage($event)">Prossima
                  pagina</button>
                <button type="button" class="btn btn-primary disabled" *ngIf="next_disabled">Prossima pagina</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>