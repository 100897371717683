<div *ngIf="utilsService.modalAppointmentConfirmationId&& !!utilsService.selectedAppointment" class="modal fade"
  [id]="utilsService.modalAppointmentConfirmationId" tabindex="-1"
  [attr.aria-labelledby]="utilsService.modalAppointmentConfirmationId" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered product-modal" role="document">
    <div class="modal-content">
      <div class="product__modal-wrapper p-relative">
        <div class="product__modal-close p-absolute">
          <button [attr.data-bs-toggle]="'modal'"
            [attr.data-bs-target]="'#' + utilsService.modalAppointmentConfirmationId">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="product__modal-inner">
          <app-appointment-confirmation-details [appointment]="utilsService.selectedAppointment!" />
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-warning btn-lg rounded" (click)="downloadInfoAppointment($event)">
          <div class="d-flex align-items-center gap-2">

            <span>Scarica</span>
            <div class="spinner-grow" role="status" *ngIf="generateReminder">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>