import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs';
import menuData from 'src/app/shared/data/menu-data';
import { Service } from 'src/app/shared/services/api.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { AuthStore } from 'src/app/shared/store/auth.store';
import { IMenuType } from 'src/app/shared/types/menu-d-t';

@Component({
  selector: 'app-nav-manus',
  templateUrl: './nav-manus.component.html',
  styleUrls: ['./nav-manus.component.scss']
})
export class NavManusComponent implements OnInit {
  public menu_data: IMenuType[] = menuData;
protected isLogged:boolean= false;
  /**
   *
   */
  constructor(protected utilService: UtilsService, public router: Router, protected authStore:AuthStore) {

    this.authStore.getUserData().pipe(
      tap(userData => {
        this.isLogged= !!userData;  
        this.populateNavItems();   
      })
    ).subscribe();
  }

  ngOnInit(): void {
    
  }

  getMenuClasses(item: IMenuType): string {
    const classes = [];

    if (item.hasDropdown && !item.megamenu) {
      classes.push('has-dropdown');
    } else if (item.megamenu) {
      classes.push('mega-menu', 'has-dropdown');
    }
    return classes.join(' ');
  }

  populateNavItems(): void {
    //categorie
    this.utilService.populateNavItems(this.menu_data);     
  }

  public async navigate(evt: Event, link: string): Promise<void> {
    var result = await this.router.navigate([link]);
    if (result)
       window.location.reload();
  }
}
