import { Component, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import { image } from 'html2canvas/dist/types/css/types/image';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-appointment-confirmation-modal',
  templateUrl: './appointment-confirmation-modal.component.html',
  styleUrls: ['./appointment-confirmation-modal.component.scss']
})

export class AppointmentConfirmationModalComponent {
  protected generateReminder: boolean = false;
  constructor(public utilsService: UtilsService) { }

  async downloadInfoAppointment(evt: Event): Promise<void> {

    this.generateReminder = true;
    try {
      const element = document.getElementById("appointment-summary")!;

      if (!!element) {

       await html2canvas(element).then(canvas => {

          const imgData = canvas.toDataURL('image/png');

          const link = document.createElement('a');
          link.href = imgData;
          link.download = `AppuntamentoUsatomania_${this.utilsService.selectedAppointment.startTime!.toLocaleString().replace(",", "_")}` + '.png';
          document.body.appendChild(link);
          link.click();
         
          this.generateReminder = false;
        });

      }
    } catch (error) {
      console.error(error);
    }
  }
}

