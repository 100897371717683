import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, of, tap } from 'rxjs';
import { Service, StaticPageServiceModel } from 'src/app/shared/services/api.service';
import { AuthStore } from 'src/app/shared/store/auth.store';

@Component({
  selector: 'app-appointment-warning',
  templateUrl: './appointment-warning.component.html',
  styleUrls: ['./appointment-warning.component.scss']
})

export class AppointmentWarningComponent {
  public isLoading: boolean = false;
  protected appointmentPageId: string = "86838439-804A-4144-88AC-E9300E51703B";
  public appointmentData: StaticPageServiceModel | undefined;
  protected isLoadingUserdata: boolean = false;
  protected hasAppointment: boolean = false;
  public isLoggedin: boolean = false;
  protected appointmentDate: string | undefined;

  constructor(protected apiService: Service, protected authStore: AuthStore, protected router: Router) {
    this.isLoading = true;
    this.isLoadingUserdata = true;

    this.authStore.getUserData().pipe(
      catchError(e => { console.log(e); return of(); }),
      tap(result => {
        if (!!result) {
          this.isLoggedin = !!result;
        }
      }),
      map(userdata => {
        if (!!userdata && !!userdata?.fiscalcode) {
          this.apiService.getappointmentbyfiscacode(userdata?.fiscalcode!).pipe(
            tap(result => {
              if (result.success) {
                
                this.hasAppointment = !!result.data ;
              //  result.data?.startTime!.setMinutes(result.data?.startTime!.getMinutes() + result.data?.startTime!.getTimezoneOffset());

                const day = result.data?.startTime!.getDate()!;
                const month = result.data?.startTime!.getMonth()! + 1; // I mesi sono indicizzati da 0
                const year = result.data?.startTime!.getFullYear()!;
                const hours = result.data?.startTime!.getHours()!;
                const minutes = result.data?.startTime!.getMinutes()!;

                this.appointmentDate = `${day}/${month}/${year} ${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
              }
            })
          ).subscribe();
        }
      })
    ).subscribe(r => { this.isLoadingUserdata = false; });
  }

  ngOnInit(): void {

    this.apiService.getStaticPage(this.appointmentPageId).pipe(
      catchError(e => {
        console.log(e)
        return of();
      }),
      map(result => {
        if (result.success) {
          this.appointmentData = result.data!;
          var div = document.getElementById("appointment-data");
          if (!!div) {
            div.innerHTML += this.appointmentData?.body!;
          }
        } else {
          console.log(result.message)
        }
        this.isLoading = false;
      })
    ).subscribe();
  }

  toStepper(evt: Event) {

    this.authStore.getUserData().pipe(
      catchError(e => { console.log(e); return of(); }),
      map(result => {
        this.isLoggedin = !!result;
        if (!this.isLoggedin) return;
        this.hasAppointment = result?.hasTakenAppointment ?? false;
        this.router.navigate(["/appointment/get-appointment-stepper"]);
      })
    ).subscribe();

  }

  goLogin(evt: Event) {
    this.router.navigate(["/appointment/get-appointment-stepper"]);
  }
}
