import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/shared/services/product.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { IPreviewQueryModel, IProductDTO, IProductDTOPaginatedResult, IProductTagServiceModel, PreviewQueryModel, ProductTagServiceModel, Service } from 'src/app/shared/services/api.service';
import { tap } from 'rxjs';

@Component({
  selector: 'app-shop-area',
  templateUrl: './shop-area.component.html',
  styleUrls: ['./shop-area.component.scss']
})
export class ShopAreaComponent implements OnDestroy {

  public products: IProductDTO[] = [];
  public productData: IProductDTO[] = [];
  public minPrice: number = 0;
  public maxPrice: number = this.productService.maxPrice;
  public niceSelectOptions = this.productService.filterSelect;
  public brands: string[] = [];
  public category: string | null = null;
  public subcategory: string | null = null;
  public paginate: any = {}; // Pagination use only
  public sortBy: string = 'asc'; // Sorting Order
  public actualPage: number  = 1; // Sorting Order
  protected isLoading: boolean = false;
  protected isLoadingMore: boolean = false;
  protected isFilterOpen: boolean = false;
  protected morePages: boolean = false;
  public searchText: string = '';
protected pages:number[]=[];

  constructor(
    protected apiService: Service,
    public productService: ProductService,
    public utilsService: UtilsService,
    private viewScroller: ViewportScroller
  ) {

    this.retriveItems();
  }

  ngOnDestroy(): void {
    this.isLoading = true;
    this.minPrice = 0;
    this.maxPrice = this.productService.maxPrice;
    this.utilsService.resetSearchFilter();
  }

  // Append filter value to Url
  updateFilter(priceFilter_min_max: any) {
    var filter = this.utilsService.getSearchFilter();
    filter.startingPrice = priceFilter_min_max.minPrice;
    filter.endingPrice = priceFilter_min_max.maxPrice;
    this.utilsService.setSearchFilter(filter);
  }

  updateSelectedTags(tags: IProductTagServiceModel[]) {
    var filter = this.utilsService.getSearchFilter();
    filter.tags = [];
    tags.forEach(t => filter.tags?.push(t as ProductTagServiceModel));
    this.utilsService.setSearchFilter(filter);
  }

  onSortingChange(value: string) {
    this.sortBy = value;
    this.sortByFilter();
  }
  // SortBy Filter
  sortByFilter() {
    //sort by utilservice
    this.isLoading = true;
    this.productData = [];

    switch (this.sortBy) {
      case 'asc':
        this.productData = this.products.sort((a, b) => {
          if (a.title! < b.title!) {
            return -1;
          } else if (a.title! > b.title!) {
            return 1;
          }
          return 0;
        })
        break;

      case 'high':
        this.productData = this.products.sort(
          (a, b) => Number(a.price) - Number(b.price)
        );
        break;

      case 'low':
        this.productData = this.products.sort(
          (a, b) => Number(b.price) - Number(a.price)
        );
        break;

      case 'sale':
        this.productData = this.products.filter((product) => !!product.discountValue && product.discountValue > 0);
        break;

      default:
        this.products.forEach(i => this.productData.push(i));
        break;
    }

    this.isLoading = false;
  }

  sortByPage(value: string) {
    this.actualPage= Number(value);
    var filter = this.utilsService.getSearchFilter();
    filter.page= this.actualPage;
    this.utilsService.setSearchFilter(filter);
    this.retriveItems();
  }
  
  onKey(event: any) {
    this.searchText = event.target.value.toString();
    var filter = this.utilsService.getSearchFilter();
    filter.searchText = this.searchText;
    this.utilsService.setSearchFilter(filter);
  }

  handleResetFilter(event: Event) {
    this.minPrice = 0;
    this.maxPrice = this.productService.maxPrice;
    this.utilsService.resetSearchFilter();
    this.retriveItems();
  }

  handleSubmitFilter(event: Event) {
    this.retriveItems();
  }

public onCategoryUpdate(e:Event){
 this.retriveItems();
}

  retriveItems() {
    this.isLoading = true;

    var filter = this.utilsService.saveSearchFilter();
    this.productService.paginatedProducts$(filter).pipe(
      tap(results => {
        this.products = [];

        this.paginate = { actualPage: results?.actualPage, totalPages: results?.totalPages }
        this.pages=  Array.from({ length: results?.totalPages!}, (_, i) => i + 1) 
        this.morePages = (this.paginate.actualPage + 1) <= this.paginate.totalPages;
        var filter = this.utilsService.getSearchFilter();
        filter.page = this.paginate.actualPage;
        this.utilsService.setSearchFilter(filter);
        this.previous_disabled= filter.page==1;
        this.next_disabled= filter.page==this.paginate.totalPages;
       
        results?.items!.forEach(r => this.products.push(r));
       
        this.maxPrice = this.productService.maxPrice;

        if(this.sortBy!='asc'){
          this.sortByFilter();
        }else{
          this.productData=[];
          this.products.forEach(e=> this.productData.push(e));
        }
        this.scrolToProducts();

        this.isLoading = false;
      })
    ).subscribe();
  }

  handleOpenFilters(evt: Event) {
    if (this.isFilterOpen) {
      this.isFilterOpen = false;
    }
    else {
      this.isFilterOpen = true;
    }
  }

  isFilteredSearch(): boolean {
    var sf = localStorage.getItem("search_filter");
    if (!!sf) {
      var f = JSON.parse(sf) as PreviewQueryModel;
      if (f.categoryListIds?.length! > 0 || (f.endingPrice! > 0 && f.endingPrice! < 999) || f.startIndex! > 0 || f.isNew || f.isPromo) {
        return true;
      }
    }

    return false;
  }


  protected next_disabled: boolean = false;
  protected previous_disabled: boolean = false;

  nextPage(event: Event) {
    var filter = this.utilsService.getSearchFilter();

    if (!!filter && filter.page == undefined) {
      //primo caricamento, prima pagina già attiva
      filter.page = 1;
    }

    if (filter.page! + 1 < this.paginate.totalPages) {
      filter.page = filter.page! + 1;
      this.utilsService.setSearchFilter(filter);
      this.paginate.actualPage = filter.page;
      this.morePages = (this.paginate.actualPage + 1) <= this.paginate.totalPages;
    } else {
      filter.page=this.paginate.totalPages;
      this.utilsService.setSearchFilter(filter);
      this.next_disabled = true;
    }

    this.retriveItems();
    this.scrolToProducts();

  }

  previousPage(event: Event) {
    var filter = this.utilsService.getSearchFilter();

    if (!!filter && filter.page == undefined) {
      //primo caricamento, prima pagina già attiva
      filter.page = 1;
    }

    if (filter.page! - 1 > 1) {
      filter.page = filter.page! - 1;
      this.utilsService.setSearchFilter(filter);
      this.paginate.actualPage = filter.page;
      this.morePages = (this.paginate.actualPage + 1) <= this.paginate.totalPages;
    } else {
      this.previous_disabled = true;
    }
    this.retriveItems();
    this.scrolToProducts();

  }

  scrolToProducts() {
    this.viewScroller.setOffset([120, 120]);
    this.viewScroller.scrollToAnchor('products');
  }
}
