import { Injectable } from "@angular/core";
import {
    Observable,
    tap,
    throwError as _observableThrow,
    catchError,
    of,
    debounceTime,
    switchMap,
    map,
} from 'rxjs';
import { Service, TokenModel, UserLogin } from "../services/api.service";
import { Router } from "@angular/router";
import { JwtService } from "../services/jwt.service";
import { createStore, withProps, select, setProps } from '@ngneat/elf';
import { devTools } from '@ngneat/elf-devtools';
import {
    persistState,
    localStorageStrategy,
} from '@ngneat/elf-persist-state';
import { AuthProps } from "../data/auth-props";
import { AuthProfile } from "../data/auth-profile";
import { UtilsService } from "../services/utils.service";

@Injectable({
    providedIn: 'root',
})
export class AuthStore {

    constructor(
        private apiService: Service,
        private router: Router,
        private jwtService: JwtService,
        private utilService: UtilsService,
    ) {
        devTools({
            maxAge: 25,
        });
    }

    private store = createStore(
        { name: 'auth' },
        withProps<AuthProps>({
            userData: undefined,
            token: undefined,
            refreshToken: undefined,
        })
    );

    persist = persistState(this.store, {
        key: 'auth',
        storage: localStorageStrategy,
        source: () =>
            this.store.pipe(
                debounceTime(1000),
                select((s) => {
                    return s;
                })
            ),
        preStorageUpdate: (storeName, state) => {
            const newState = { ...state };
            if (storeName === 'auth') {
            }
            return newState;
        },
    });

    public IsLogged$: Observable<boolean> = this.store.pipe(
        select((store) => {

            return !!store && !!store.userData;
        })
    );

    public getUserData(): Observable<AuthProfile | null | undefined> {
        return this.userData$;
    }
    public getTokenModel(): Observable<string | null | undefined> {
        return this.store.pipe(
            select(s => {
                return s.token
            })
        );
    }

    private userData$ = this.store.pipe(
        select((state) => {
            if (!!state && !!state.userData) {

                //ho un token, ma non so se valido
                var isValidToken =
                    !!state.token && !this.jwtService.isExpiredToken(state.token!);

                if (!isValidToken) {
                    this.logout();
                }

                return state.userData;
            } else {
                return null;
            }
        })
    );

    public SetDataInStore(
        token: string | undefined,
        refreshToken: string | undefined
    ): void {
        var userdata = new AuthProfile(this.jwtService, token!);
        //update store data
        this.store.update(
            setProps({
                userData: userdata,
                token: token,
                refreshToken: refreshToken,
            })
        );
    }

    public RemoveAppointment(): void {

        this.store.pipe(
            tap(data => {
                if (!!data.userData) {

                    data.userData.appointmentDate = undefined;
                    data.userData.appointmentId = '';
                    data.userData.hasTakenAppointment = false;
                    //update store data

                    this.store.update((state) => ({
                        ...state,
                        userData: data.userData,
                        token: data.token,
                        refreshToken: data.refreshToken,
                    }));                 
                }
            })).subscribe()
    }

    public AddAppointment(appDate:string, appId:string): void {

        this.store.pipe(
            tap(data => {
                if (!!data.userData) {

                    data.userData.appointmentDate = appDate;
                    data.userData.appointmentId = appId;
                    data.userData.hasTakenAppointment = true;
                    //update store data
                    this.store.update(
                        setProps({
                            userData: data.userData,
                            token: data.token,
                            refreshToken: data.refreshToken,
                        })
                    );
                }
            })).subscribe()
    }

    public ResetDataInStore(): void {
        this.store.update((state: any) => ({
            ...state,
            userData: null,
            token: null,
            refreshToken: null,
        }));
    }

    public logout(): void {
        localStorage.removeItem('auth');
        this.ResetDataInStore();
        this.router.navigate(['/']);

        return;
    }
}
