<!-- slider area start -->
<div class="slider-four">

    <section class="slider__area slider__area-4 p-relative">
        <div class="slider-active swiper-container" #heroSliderContainer>
            <div class="swiper-wrapper">
                <div *ngFor="let item of aws_home_slider"
                    class="single-slider single-slider-2 slider__height-4 d-flex align-items-center swiper-slide"
                    [style.backgroundImage]="'url(' + item.bgImg + ')'">
                    <div class="container" *ngIf="(!!item.title|| !!item.subtitle|| !!item.btnTitle)">
                        <div class="row">
                            <div class="col-xl-7 col-lg-7 col-md-9 col-sm-11 col-12 bg-white bg-opacity-50 p-4">
                                <div class="slider__content slider__content-4">
                                    <h2 *ngIf="!!item.title">{{item.title}}</h2>
                                    <p  *ngIf="!!item.subtitle">{{item.subtitle}}</p>
                                    <a href="{{item.btnLink}}" target="_blank" class="os-btn os-btn-2" *ngIf="!!item.btnTitle">{{item.btnTitle}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>               
            </div>
            <div class="tp-slider-dot tp-swiper-dot"></div>
        </div>
    </section>
</div>
<!-- slider area end -->