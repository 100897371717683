
<!-- header start -->
<app-header-one [transparent]="false" [white_bg]="true"></app-header-one>
<!-- header end -->

<main>

  <!-- breadcrumb start -->
  <app-breadcrumb-one title="Dettaglio prodotto" subtitle="Dettaglio prodotto"></app-breadcrumb-one>
  <!-- breadcrumb end -->


  <!-- shop details area start -->
  <div *ngIf="product">
    <app-product-details-area [product]="product"></app-product-details-area>
  </div>
  <!-- shop details area end -->

  <ng-container *ngIf="!isLoading">
    <!-- related products start -->
    <app-related-products [productId]="product!.id" *ngif="!!product; else noitemfound" ></app-related-products>
    <!-- related products end -->
    <ng-template #noitemfound>
      <div class="container">
        <div class="alert w-100">
          <h4 class="alert-heading text-center">Ops.. Siamo spiacenti ma il prodotto non è più disponibile.</h4>
          <hr />
          <div class="d-flex flex-row justify-content-center">
            <a href="/" class="btn btn-link os-btn">Home</a>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <app-loader [IsLoading]="isLoading"></app-loader>
  
</main>
