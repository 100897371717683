import { Component, Input, OnInit } from '@angular/core';
import social_links, { ISocial } from '../../data/social-data';
import { CompanyInfoServiceModel, Service } from '../../services/api.service';
import { Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {
  @Input() box_style: Boolean = false;
  public social_links: ISocial[] = social_links;
  protected isLoading: boolean = false;
  protected comapanyData!: CompanyInfoServiceModel;
  protected footer_company_presentation: string = "footer-company-presentation";

  constructor(protected apiService: Service, protected router: Router) {
    this.isLoading = true;
  }

  ngOnInit(): void {
    this.apiService.getCompanyUsatomania().pipe(
      catchError(e => {
        console.log(e); return of();
      }),
      map(result => {
        if (result.success) {

          this.comapanyData = result.data!;
          var footer_description = document.getElementById(this.footer_company_presentation);
          if (!!footer_description) {
            footer_description.innerHTML = this.comapanyData.companyPresentationHtml ?? "";
          }
        } else {
          console.log(result.message);
        }
        this.isLoading = false;
      })
    )
      .subscribe();
  }
}
