import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeliveryCompanyServiceModel } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-delivery-company',
  templateUrl: './delivery-company.component.html',
  styleUrls: ['./delivery-company.component.scss']
})
export class DeliveryCompanyComponent {

  @Input() deliveryCompany:DeliveryCompanyServiceModel|undefined;
  @Input() showNotes:boolean=false;
  @Output() onDeliveryChange: EventEmitter<DeliveryCompanyServiceModel> = new EventEmitter<DeliveryCompanyServiceModel>();

  
  isSelected(id: string): boolean {
    var d = localStorage.getItem("delivery");
    if (!!d) {
      var delivery = JSON.parse(d);
      return delivery.id === id;
    }
    else {
      return false;
    }
  }

  handleSelectCompanyDelivery(evt: Event, delivery: DeliveryCompanyServiceModel) {
    localStorage.removeItem("delivery");
    localStorage.setItem("delivery", JSON.stringify(delivery));
    this.onDeliveryChange.emit(delivery);
  }

}
