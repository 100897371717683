<ul class="extra-info">
  <li>
    <div class="my-account">
      <div class="extra-title">
        <h5 i18n>Dati Personali</h5>
      </div>
      <ul>
        <li><a routerLink="/shop/cart" i18n>Carrello</a></li>
        <ng-container  *ngIf="!(authStore.IsLogged$ | async );else logged">

       <li><a routerLink="/pages/login" i18n>Entra</a></li>
          <li><a routerLink="/pages/register" i18n>Registrati</a></li>
        </ng-container>
        
        <ng-template #logged>
          <li><a routerLink="/pages/account" i18n>Profilo</a></li>
          <br />
          <li><a class="btn-link os-btn w-100 p-1" (click)="logout($event)">Esci</a></li>
        </ng-template>
      </ul>
    </div>
  </li>
</ul>