import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swiper from 'swiper';
import { EffectFade, Pagination } from 'swiper/modules';
import { IHeroSlider } from 'src/app/shared/types/hero-slider-t';
import { IProduct, Service } from 'src/app/shared/services/api.service';
import { map } from 'rxjs';


@Component({
  selector: 'app-hero-slider-four',
  templateUrl: './hero-slider-four.component.html',
  styleUrls: ['./hero-slider-four.component.scss']
})
export class HeroSliderForComponent implements OnInit {
  @ViewChild('heroSliderContainer') heroSliderContainer!: ElementRef;
  public aws_home_slider: IHeroSlider[] = [];

  public swiperInstance: Swiper | undefined;

  public trendingProducts: IProduct[] = [];
  public perView: number = 10;

  constructor(private service: Service) {

  }
  // handle per view
  handlePerView() {
    this.perView = this.perView + 4;
  }
  ngOnInit(): void {
    this.service.getAllSliders().pipe(
      map(i => {
        if (!!i && i.success) {
          this.aws_home_slider = [];
          i.data?.forEach((item, i) => {
            this.aws_home_slider.push({
              id: i,
              idSlider: item.id!,
              bgImg: item.imageLink!,
              title: item.title!,
              sm_title: item.title!,
              subtitle:item.subTitle,
              btnLink: item.buttonLink,
              btnTitle:item.buttonLabel,
            } as IHeroSlider);
          });
        }
      })
    ).subscribe();
  }



  ngAfterViewInit() {
    if (this.heroSliderContainer) {
      this.swiperInstance = new Swiper('.slider-active', {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        effect: 'fade',
        modules: [Pagination, EffectFade],
        pagination: {
          clickable: true,
          el: '.tp-slider-dot',
        },
      });
    }
  }
}
