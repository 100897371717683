/** Classe che contiene la mappatura dei claims */
export class Claims {

    static userDetailId: string = "UserDetail";
    static userSurname: string = "UserSurname";
    static userName: string = "UserName";
    static email: string = "Email";
    static phone: string = "Tel";
    static cardCode: string = "CardCode";
    static roles: string = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
    static fiscalcode: string = "FiscalCode";
    static nameidentifier:string="http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
}