<div class="d-flex flex-column" *ngIf="!isDefaulPaymentLoading">

    <div class="accordion" id="payments-accordeon">
        <div *ngIf="payments.length>0; else default">
            <div class="card" *ngFor="let payment of payments">
                <div class="card-header" [id]="('pym-main-'+payment.id)">
                    <h5 class="mb-0">
                        <button class="btn-link" type="button" data-bs-toggle="collapse"
                            [attr.data-bs-target]="('#'+payment.id)" aria-expanded="true" aria-controls="collapseOne">
                            {{payment.name}}
                        </button>
                    </h5>
                </div>
                <div [id]="payment.id" class="collapse show" [attr.aria-labelledby]="(payment.name)"
                    data-bs-parent="#payments-accordeon">">
                    <div class="card-body">
                        {{payment.notes}}
                    </div>
                </div>
            </div>
        </div>
        <ng-template #default>
            <div class="card-header" id="payment-default">
                <h5 class="mb-0">
                    <button class="btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#pym-default"
                        aria-expanded="true" aria-controls="collapseOne">
                        {{bankAccount?.paymentName}}
                    </button>
                </h5>
            </div>
            <div id="pym-default" class="collapse show" aria-labelledby="payment-default"
                data-bs-parent="#payments-accordeon">
                <div class="card-body">
                    <div class="d-flex flex-column row-gap-2 p-2">
                        <div class="">
                            {{bankAccount?.companyName}}
                        </div>
                        <div class="pt-10">
                            {{bankAccount?.companyBank}}
                        </div>
                        <div class="">
                            {{bankAccount?.bankAddress}}
                        </div>
                        <div class="row flex-wrap gap-2 row-gap-2">
                            <input type="text" [value]="bankAccount?.iban" class="form-control w-100" disabled  id="iban-pym"/>
                            <button class="os-btn" type="button" (click)="copyText($event, 'iban-pym')">Copia IBAN</button>  
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

    </div>
</div>